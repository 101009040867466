import React,{useEffect, useState} from "react";
import "./Franchise.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import { useFormik } from "formik"
import axios from "axios";
import { API } from "../config/api";
import { showErrorMsg,showSuccessMsg } from "./helpers/message";
import { showLoader } from "./helpers/loading";

const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; 
const emailRegex =/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Franchise = () => {
  const classes = useStyles();
  const [dRate,setDRate]=useState([])
  // const [state, setState] = React.useState({
  //   investment: "",
  //   register: "",
  // });

  // const handleChange = (event) => {
  //   const name = event.target.name;
 
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  const bull = <span className={classes.bullet}>•</span>;


//  useEffect(async()=>{
// await axios.get(`${API}/deliveryRate`).then((res)=>setDRate(res.data))

//  },[])

  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");





  
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }
  const displaySuccessMsg=(setSuccessMsg, timeout)=> {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    },1000);
    return timeout;
  }
  const validationSchema = Yup.object({
    name:Yup.string().required("Please enter Name").min(4,"Name must be at least 4 characers"),
    email:Yup.string().required("Please enter Email").matches(emailRegex,"Please enter valid Email"),
    mobile:Yup.string().required("Please enter Mobile No").matches(phoneRegex,"please enter valid Mobile No"),
    cityName:Yup.string().required("Please enter City Name"),
    selectInvestment:Yup.string().required("Please select Investment"),
    registerFor:Yup.string().required("Please select Register For")
    
  });
  



  const formik = useFormik({
    initialValues: {name:"", email: "",mobile:"", cityName: "",selectInvestment:"",registerFor:""},
    validationSchema: validationSchema,
    onSubmit: async (franchise) => {
      setErrMsg(false);
   
        setLoading(true);

      let data = {
        name: franchise.name,
        email: franchise.email,
        mobile:franchise.mobile,
        cityName:franchise.cityName,
        selectInvestment:franchise.selectInvestment,
        registerFor:franchise.registerFor
      };
       const config = { headers: { "Content-Type": "application/json" } };
   
      await axios.post(`${API}/applyForFranchise`,data,config).then((res)=>{
     
        setMsg(res.data.message)
if(res.data.status=="SUCCESS"){
  setSuccessMsg(true)
   
  timeout = displaySuccessMsg(setSuccessMsg, timeout);
}
else{
  setErrMsg(true)
   
  timeout = displayErrMsg(setErrMsg, timeout);
}
setLoading(false)
      }
      
      )
   
    }

  
  })
  return (
    <div>
      <main>
        {/* <section className="">
          <div className="company_Bg">
            <img src="assets/images/probanner.jpg" />
          </div>
        </section> */}
        <section className="contact_details ">
          <div className="row ">
            <div className="col-lg-6 ">
              <form
                style={{
               
                  margin: "auto",
                  width: "75%",
                  backgroundColor: "whitesmoke",
                  borderRadius: "25px",
                  marginBottom: "10px",
                }}
                onSubmit={formik.handleSubmit}
              >
                <fieldset
                  style={{
                    // border: "1px solid #003c7e",
                    padding: "30px 10px 25px 0px",
                  }}
                >
                  <h4 style={{ textAlign: "center", color: "#003c7e" }}>
                    <b>Apply For Franchise</b>
                  </h4>
<br/>
                  {successMsg && showSuccessMsg(msg, successMsg)}
                  {errMsg && showErrorMsg(msg, errMsg)}
                  {loading && showLoader()}
                  <div className="row">
                    <div className="col-lg-6">
                      <TextField   id="name"
                    label="name"
                    variant="standard"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name && formik.touched.name}
                    helperText={
                      formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : ""
                    }
                    autoFocus
                    inputProps={{
                      autocomplete: 'new-name',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }} />
                    </div>
                    <div className="col-lg-6">
                      <TextField   id="email"
                    label="email"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email && formik.touched.email}
                    helperText={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ""
                    }
                    
                    inputProps={{
                      autocomplete: 'new-email',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }} />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <TextField   id="mobile"
                    label="mobile"
                    variant="standard"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile && formik.touched.mobile}
                    helperText={
                      formik.errors.mobile && formik.touched.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                   
                    inputProps={{
                      autocomplete: 'new-mobile',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }} />
                    </div>
                    <div className="col-lg-6">
                      <TextField   id="cityName"
                    label="City Name"
                    variant="standard"
                    value={formik.values.cityName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.cityName && formik.touched.cityName}
                    helperText={
                      formik.errors.cityName && formik.touched.cityName
                        ? formik.errors.cityName
                        : ""
                    }
               
                    inputProps={{
                      autocomplete: 'new-cityName',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }} />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="investment" error={formik.errors.selectInvestment && formik.touched.selectInvestment}
                          helperText={
                            formik.errors.selectInvestment && formik.touched.selectInvestment
                              ? formik.errors.selectInvestment
                              : ""
                          }>
                          Select Investment
                        </InputLabel>
                        <Select
                          native
                          // value={state.investment}
                          // onChange={handleChange}
                          // inputProps={{
                          //   name: " Investment",
                          //   id: "investment",
                          // }}

                          id="selectInvestment"
                          label="selectInvestment"
                          variant="standard"
                          value={formik.values.selectInvestment}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                
                          inputProps={{
                            autocomplete: 'new-selectInvestment',
                            form: {
                              autocomplete: 'off',
                            },
                             style: { textTransform:'lowercase' }
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="15k-1lac">15k-1lac</option>
                          <option value="1lac-2lac">1lac-2lac</option>
                          <option value="3lac-4lac">3lac-4lac</option>
                        </Select>
                      </FormControl>
                    </div>
                    {/* age-native-simple */}
                    <div className="col-lg-6">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="register" error={formik.errors.registerFor && formik.touched.registerFor}
                          helperText={
                            formik.errors.registerFor && formik.touched.registerFor
                              ? formik.errors.registerFor
                              : ""
                          } >
                          Register for?
                        </InputLabel>
                        <Select
                          native
                          // value={state.Register_for}
                          // onChange={handleChange}
                          // inputProps={{
                          //   name: "Register_for",
                          //   id: "register",
                          // }}

                          id="registerFor"
                          label="registerFor"
                          variant="standard"
                          value={formik.values.registerFor}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                
                          inputProps={{
                            autocomplete: 'new-registerFor',
                            form: {
                              autocomplete: 'off',
                            },
                             style: { textTransform:'lowercase' }
                          }}
                        >
                              <option aria-label="None" value="" />
                          <option value="franchise">Register for Franchise</option>
                          <option value="merchant">Register for Marchant</option>
                          <option value="bike">Register for Bike</option>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <br />
                  <div className="franchise-submit">
                    <Button variant="contained" type="submit">Submit</Button>
                  </div>
                </fieldset>
              </form>
              <form noValidate autoComplete="off"></form>
            </div>
            <div className="col-lg-6 ">
           <div style={{
               
               margin: "auto",
               width: "75%",
               backgroundColor: "whitesmoke",
               borderRadius: "25px",
               marginBottom: "10px",
             }}>
              <img
                src="assets/img/cards/save.png"
                               style={{ borderRadius: "20px", alignContent: "center"}}
              />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="contact_details">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <form noValidate autoComplete="off">
                    <TextField id="standard-basic" label="Name" />
                  </form>
                </div>
                <div className="col-lg-6">
                  <form noValidate autoComplete="off">
                    <TextField id="standard-basic" label="Email" />
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <form noValidate autoComplete="off">
                    <TextField id="standard-basic" label="Phone Number" />
                  </form>
                </div>
                <div className="col-lg-6">
                  <form noValidate autoComplete="off">
                    <TextField id="standard-basic" label="City Name" />
                  </form>
                </div>
              </div>
              <div className="row">
                <div classname="col-md-6">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">
                      Select Investment
                    </InputLabel>
                    <Select
                      native
                      value={state.Investment}
                      onChange={handleChange}
                      inputProps={{
                        name: " Investment",
                        id: "age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>15k-1lac</option>
                      <option value={20}>1lac-2lac</option>
                      <option value={30}>3lac-4lac</option>
                    </Select>
                  </FormControl>
                </div>
                <div classname="col-md-6">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">
                      Register for?
                    </InputLabel>
                    <Select
                      native
                      value={state.Register_for}
                      onChange={handleChange}
                      inputProps={{
                        name: "Register_for",
                        id: "age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>Register for Franchise</option>
                      <option value={20}>Register for Marchant</option>
                      <option value={30}>Register for Bike</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <form noValidate autoComplete="off">
                <div className="franchise-submit">
                  <Button variant="contained">Submit</Button>
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <img src="assets/img/cards/save.png"></img>
            </div>
          </div>
        </section> */}
        <section className="company_det">
          <div className="row franchise-container">
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fa fa-mobile" style={{ color: "green" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "green" }}>Mobile No</h4>
                        <p>7473839292</p>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fas fa-envelope" style={{ color: "red" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "red" }}>Email Id</h4>
                        <a className="text-lowercase">xyz234@gmail.com</a>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fas fa-clock" style={{ color: "blue" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "blue" }}>Timing</h4>
                        <p>8AM to 12PM</p>
                        <a>12/7</a>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Franchise;
