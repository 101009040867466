import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import NavBar2Dropdown from './NavBar2Dropdown';
const NavBarTop2 = () => {
  return (
    <div className=' res-box'>
    <div  className=" res-text ">  
              

              <Link className="dropdown-item" to="/">
                Home
              </Link>
              <Link className="dropdown-item" to="/about">
                About Us
              </Link>
              <div style={{display:"flex", alignItems:"center",justifyContent:"center",marginLeft:90}}> 
              <NavBar2Dropdown/>
              </div>
              </div>
    </div>

  )
}

export default NavBarTop2

// <Link className="dropdown-item" to="/distributercontact">
//                       Distributor Contact
//                     </Link>
//                     <Link className="dropdown-item" to="/companycontact">
                  
//                       Company Contact
//                     </Link>
           
//               {/* <div className="">
  
//   <a className="dropdown-toggle"  id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//   Contact
//           </a>
//           <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        
//           <Link className="dropdown-item" to="/distributercontact">
//                       Distributor Contact
//                     </Link>
//                     <Link className="dropdown-item" to="/companycontact">
                  
//                       Company Contact
//                     </Link>
//           </div>
//                   </div> */}
//               <Link className="dropdown-item" to="/franchise">
//                 Apply for Franchise
//               </Link>