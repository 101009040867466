import React, { useEffect,
  //  createContext,
    useState, useContext } from "react";
import { StateContext } from "../context/ContextProvider";
import { deleteFromCart, getCart } from "./helpers/localstorage";
import LoginPopup from "./LoginPopup";
import { useNavigate } from "react-router-dom";
import "./cart.css";
import { setItemToLocalStorage } from "../utils/localStorageActions";

// export const DeliveryContext = createContext();

const Cart = () => {
  const { cart, setCart, auth, isOpen, setIsOpen, setRedirect } =
    useContext(StateContext);
  const [dc, setDelivery] = useState(10);


  const calculateDeliveryCharges = () => {
    console.log('calculateDeliveryCharges', cart)
    const totalPrice = cart
      .map(
        (product) =>
          product?.itemPrice * product?.count
      )
      .reduce((a, b) => a + b, 0)
      .toFixed(2);

    return totalPrice >= 500 ? setDelivery(15) : setDelivery(10)
  }
  useEffect(() => {
    calculateDeliveryCharges();
  });

  // const getDisccount = () => {
  //   console.log("cart", cart);

  //   if (cart.length > 0) {
  //     return cart.map((pr) => {
  //       if (pr.discountType === "FIXED") {
  //         return parseFloat(pr?.customerDiscount);
  //       } else {
  //         return (
  //           (parseFloat(pr?.itemPrice) *
  //             parseFloat(pr?.count) *
  //             parseFloat(pr?.customerDiscount)) /
  //           100
  //         );
  //       }
  //     });
  //   }
  // };
  const getDisccount = () => {
    console.log("cart", cart);

    if (cart.length > 0) {
      return cart.reduce((sum, pr) => {
        if (pr.discountType === "FIXED") {
          return sum + parseFloat(pr?.customerDiscount);
        } else {
          return (
            sum +
            (parseFloat(pr?.itemPrice) *
              parseFloat(pr?.count) *
              parseFloat(pr?.customerDiscount)) /
            100
          );
        }
      }, 0);
    }
    return 0; // Return 0 if cart is empty
  };
  console.log(getDisccount());

  const getGST = (product) => {
    if (cart.length > 0) {
      // console.log(cart);
      const discount = getDisccount()
      const totalPrice = cart
        .map((product) => (parseFloat(product?.itemPrice) - discount) * product?.count)
        .reduce((a, b) => a + b, 0);
      //   .toFixed(2) *
      //   parseFloat(product?.gst)) /
      // 100
      const gstAmount = (totalPrice * parseFloat(product?.gst)) / 100
      return gstAmount
    }
  };

  console.log('================================', getGST(), "================================", getDisccount())


  const getTotalGST = () => {
    let totalGST = 0;
    for (let i = 0; i < cart.length; i++) {
      const item = cart[i];
      console.log(item);
      const gst = getGST(item);
      console.log(gst);
      totalGST = gst;
    }
    return totalGST.toFixed(2);
  };

  console.log(getTotalGST());

  const navigate = useNavigate();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleCheckout = (evt) => {
    if (auth) {
      navigate("/shipping");
    } else {
      setRedirect(true);
      togglePopup();
    }
  };

  const handleQtyChange = (product, type) => {
    if (!["incr", "decr"].includes(type)) return;

    let updatedCart;

    if (type === "incr") {
      updatedCart = cart.map((cartItem) =>
        cartItem?._id === product?._id
          ? { ...cartItem, count: cartItem.count + 1 }
          : cartItem
      );
    } else {
      if (product.count === 1) {
        updatedCart = cart.filter((cartItem) => cartItem._id !== product._id);
      } else {
        updatedCart = cart.map((cartItem) =>
          cartItem?._id === product?._id
            ? { ...cartItem, count: cartItem.count - 1 }
            : cartItem
        );
      }
    }

    // console.log("updatedCart", updatedCart);

    setCart(updatedCart);
    setItemToLocalStorage("cart", updatedCart);
  };

  return (
    // <DeliveryContext.Provider value={dc}>
      <div>
        {cart.length <= 0 ? (
          <div className="mx-5 px-5 py-5 jumbotron">
            <h1 className="display-4">
              Your cart is empty{" "}
              <button
                className="btn btn-light text-primary ml-4"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </h1>
          </div>
        ) : (
          <div className="ms-5 me-5 px-5 crd bg-white rounded mb-5">
            <h4>Shopping Cart</h4>
            {isOpen && <LoginPopup handleClose={togglePopup} />}
            {/* cart item */}
            <div className="cart-page">
              <div className="small-container">
                <table>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Sub total</th>
                  </tr>
                  {cart.map((product) => (
                    <tr key={product._id}>
                      <td>
                        <div className="cart-info">
                          <img
                            className="img2"
                            src={product?.image[0]}
                            alt={product?.itemName}
                          />
                          <div className="res">
                            <h6>{product?.itemName}</h6>
                            <span> ₹{product?.itemPrice}</span>

                            <br />
                          </div>
                        </div>
                      </td>
                      <td>
                        <span>
                          <button
                            style={{
                              width: "20px",
                              marginRight: "10px",
                              border: "0px",
                            }}
                            className="rmv"
                            onClick={() => {
                              handleQtyChange(product, "decr")
                            }}
                          >
                            -
                          </button>
                          {product.count}
                          <button
                            style={{
                              width: "20px",
                              border: "0px",
                              marginLeft: "10px",
                            }}
                            className="rmv"
                            onClick={() => {
                              handleQtyChange(product, "incr")
                            }
                            }
                          >
                            +
                          </button>
                        </span>
                        <span style={{ margon: "auto" }}>
                          <button
                            style={{
                              width: "65px",
                              border: "0px",
                            }}
                            className="delete"
                            onClick={() => {
                              deleteFromCart(product);

                              setCart(getCart());
                            }}
                          >
                            Delete
                          </button>
                        </span>
                      </td>
                      <td>
                        {" "}
                        <p>
                          {" "}
                          ₹{(product?.itemPrice * product?.count).toFixed(2)}
                        </p>
                      </td>
                    </tr>
                  ))}
                </table>
                <div className="total-price">
                  <table>
                    <tr>
                      <td>
                        Item total({" "}
                        {cart
                          .map((product, index) => product?.count)
                          .reduce((a, b) => a + b, 0)}
                        )
                      </td>
                      <td>
                        {/* ₹ {total} */}
                        {/* {console.log(total)} */}
                        {cart
                          .map(
                            (product) =>
                              product?.itemPrice * product?.count
                          )
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr className="text-muted">
                      <td>Discount</td>
                      <td className="text-danger">₹{getDisccount()}</td>
                    </tr>
                    <tr className="text-muted">
                      <td>GST(in %)</td>
                      <td className="text-success">₹{getTotalGST()}</td>
                    </tr>
                    <tr className="text-muted">
                      <td>Delivery Charges</td>
                      <td className="text-success">₹{dc}</td>
                    </tr>

                    <tr>
                      <td>
                        Grand Total
                        <br />
                        <small className="text-muted">[incl. all taxes]</small>
                      </td>
                      <td>
                        ₹
                        {(
                          cart
                            .map(
                              (product, index) =>
                                product?.itemPrice * product?.count
                            )
                            .reduce((a, b) => a + b, 0) + dc + parseFloat(getTotalGST()) - getDisccount()).toFixed(0)}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" style={{ marginRight: "auto" }}>
                        <div
                          className="btn btn-success  btn-block proceed "
                          onClick={handleCheckout}
                        >
                          Proceed
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
  );
};
export default Cart;
