import React from 'react';
import './TC.css';

const TC = () => {
	return (
		<div className="Terms_conditions_container mx-auto w-75 bs crd">
			<div className="card mx-5 text-justify ">
				<h4 className="text-center text-black pt-3">Terms and Conditions</h4>
				<h5 className="text-center py-3">Growup Sindhu Technology & Solutions Pvt. Ltd.</h5>
				<h6 className="text-center pb-2">
					Website - <b className="text-lowercase">www.orbitmarts.com</b>
				</h6>
				<h6 className="General_terms_condition_heading text-left py-3">General Terms & Conditions:</h6>
				<p>
					<small className="py-5">
						This document is an electronic record in terms of Information Technology Act, 2000 and rules
						there under as applicable and the amended provisions pertaining to electronic records in various
						statutes as amended by the Information Technology Act, 2000. This electronic record is generated
						by a computer system and does not require any physical or digital signatures.
					</small>
					<br />
					<br />
					<small className="py-5">
						This document is published in accordance with the provisions of Rule 3 (1) of the Information
						Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
						regulations, privacy policy and Terms of Use for access or usage of www.orbitmarts.com website{' '}
					</small>
					<br />
					<br />
					<small className="py-5">
						The domain name www.orbitmarts.com (hereinafter referred to as "Website") is owned by Growup
						Sindhu Technology & solutions Private Limited a company incorporated under the Companies Act,
						1956 with its registered office at H No.3158 Shivshakti-2, Near Kudaleshwar Mandir, Kudal, Dist-
						Sindhudurg, Maharashtra-416520.India. Your use of the Website and services and tools are
						governed by the following terms and conditions ("Terms of Use") as applicable to the Website
						including the applicable policies which are incorporated herein by way of reference. If You
						transact on the Website, you shall be subject to the policies that are applicable to the Website
						for such transaction. By mere use of the Website, you shall be contracting with Growup Sindhu
						Technology & Solutions Private Limited and these terms and conditions including the policies
						constitute Your binding obligations. For the purpose of these Terms of Use, wherever the context
						so requires "You" or "User" shall mean any natural or legal person who has agreed to become a
						Customer on the Website by providing Registration Data while registering on the Website as
						Registered User using the computer systems. Orbit Mart allows the User to surf the Website or
						making purchases without registering on the Website. The term "We", "Us", "Our" shall mean
						Growup Sindhu Technology & Solutions Private Limited.
					</small>
					<br />
					<br />
					<small className="py-5">
						When You use any of the services provided by Us through the Website, including but not limited
						to, (e.g., Product Reviews, Merchant Reviews), You will be subject to the rules, guidelines,
						policies, terms, and conditions applicable to such service, and they shall be deemed to be
						incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of
						Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of
						these Terms of Use, at any time without any prior written notice to You. It is Your
						responsibility to review these Terms of Use periodically for updates / changes. Your continued
						use of the Website following the posting of changes will mean that You accept and agree to the
						revisions. As long as You comply with these Terms of Use, we grants You a personal,
						non-exclusive, nontransferable, limited privilege to enter and use the Website.
					</small>
					<br />
					<br />
					<small>
						<b className="Terms_conditions_bolded_content py-5">
							ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS
							AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE
							PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and
							agree to be bound by Orbit Mart Policies as amended from time to time.
						</b>
					</small>
				</p>
			</div>
		</div>
	);
};

export default TC;
