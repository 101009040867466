import "./App.css";
import About from "./compenent/about";
import Index from "./compenent/index";
import Marchant from "./compenent/marchant";
import { Routes, Route, useNavigate } from "react-router-dom";
import Faq from "./compenent/Faq";
//import Navbar from "./compenent/Navbar";
import Footer from "./compenent/Footer";
import Franchise from "./compenent/Franchise";
import Companycontact from "./compenent/Companycontact";
import Distributercontact from "./compenent/Distributercontact";
//import MyOrder from "./compenent/MyOrder";
import BestOfferall from "./compenent/BestOfferall";
import MerchantProduct from "./compenent/merchantProduct";
import MerchantProDetails from "./compenent/MerchantProDetails";
import RestMarchant from "./compenent/RestMarchant";
import AgriMarchant from "./compenent/AgriMarchant";
import MediMarchant from "./compenent/MediMarchant";
import DairyMarchant from "./compenent/DairyMarchant";
import RamProduct from "./compenent/RamProduct";
import SundarProducts from "./compenent/SundarProducts";
import GovindProducts from "./compenent/GovindProducts";
import AmulProducts from "./compenent/AmulProducts";
import Merchant from "./compenent/Merchant";
import ProductList from "./compenent/ProductList";
import { MerchantProvider } from "./compenent/MerchantProvider";
import Cart from "./compenent/Cart";
import AddAdress from "./compenent/AddAdress";
//import NavBarHeader from "./compenent/NavBarHeader";
import DomainType from "./compenent/DomainType";
import { StateContext } from "./context/ContextProvider";
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
//import Register from "./Register";
//import Login from "./Login";
import axios from "axios";
//import Category from "./compenent/Category";
import MyProfile from "./compenent/MyProfile";
// import Notification from "./compenent/Notification";
import OrderHistory from "./compenent/OrderHistory";
import CancelOrder from "./compenent/CancelOrder";
import OrderDetails from "./compenent/OrderDetails";
import PaymentMethod from "./compenent/PaymentMethod";
import SeeAll from "./compenent/SeeAll";
import ViewOrderDetails from "./compenent/ViewOrderDetails";
import ScrollTop from "./compenent/ScrollTop";
import Seeallcategarious from "./compenent/Seeallcategarious";
import SeeallRecommended from "./compenent/SeeallRecommended";
import SeeallGroupcategories from "./compenent/SeeallGroupcategories";
import Todaydealsseeall from "./compenent/Todaydealsseeall";
import VerifyMobile from "./VerifyMobile";
import DisplayCategories from "./compenent/DisplayCategories";
import DisplayGroupCategories from "./compenent/DisplayGroupCategories";
// import { getCart } from "./compenent/helpers/localstorage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import MerchantNotAvailable from "../src/compenent/MerchantNotAvailable";
import OrderPlaced from "./compenent/OrderPlaced";
import { showErrorMsg } from "../src/compenent/helpers/message";
import { showSpinner1 } from "./compenent/helpers/loading";
import MyOrderDisplay from "./compenent/MyOrderDisplay";
import NavBarTop from "./compenent/NavBarTop";
//import FooterBottom from "./compenent/FooterBottom";

// import { onMessageListener } from "./firebaseInit";
// import Notifications from "./compenent/Notifications/Notifications";
// import ReactNotificationComponent from "./compenent/Notifications/ReactNotification";
import PrivacyPolicy from "./compenent/Pc";
// import PrivacyPolicy from "./PrivacyPolicy";
import TC from "./compenent/TC";
import MyNotification from "./compenent/MyNotification";
import MyCategory from "./compenent/MyCategory"; 
import PageNotFound from "./compenent/PageNotFound";
import { getCart } from "./compenent/helpers/localstorage";
//import { responsiveFontSizes } from "@material-ui/core";
// import ProductDetails from "./compenent/ProductDetailsData";
import ProductDetailsData from "./compenent/ProductDetailsData";
import MedicalPrescription from "./compenent/MedicalPrescription";
import { getToken, onMessageListener } from './firebaseInit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { API } from './config/api';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  m: 4,
};
// export const API = "http://143.244.132.221:4002/api/v1";

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [localpin, setLocalPin] = useState("");

  const [errMsg, setErrMsg] = useState(false);
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 1000);
    return timeout;
  };
  // const { register, setRegister, setCart, verifymobile } =
  // useContext(StateContext);

  const {
    setDomainTypeL,
    setCart,
    setCategoryL,
    setGroupCategoryL,
    pincode,
    setIsPinOpen,
    isPinOpen,
    setPincode,
    setGroceryId,
    setDistributorId,
    distributorId,
    setGroceryMerchant,

    setCityName,
  } = useContext(StateContext);

  //const [grocId, setGrocId] = useState(""); //this is for local state

  useEffect(() => {
    //get city name
    const getCityName = async () => {
      await axios
        .get(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((res) => {
          console.log("res city name", res);
          // setCityName(res.data[0].PostOffice[0].Block);
        });
    };

    //get distributor based on pincode
    const getDistributor = async () => {
      try {
        const res = await axios.get(
          `${API}/auth/distributor/${pincode}`
        );
        setDistributorId(res.data.data._id);
        window.sessionStorage.setItem("distributorId", res.data.data._id);
      } catch (err) {
        console.log(err);
        setDistributorId("");
        window.sessionStorage.removeItem("distributorId");

        // await axios
        //   .get(`http://143.244.132.221:4002/api/v1/auth/distributor/${pincode}`)
        //   .then((res) => {
        //     setDistributorId(res.data.data._id);
        //     window.sessionStorage.setItem("distributorId", res.data.data._id);
        //   })
        //   .catch((err) => {
        //   });
      }
    };

    //get all domaintype
    const getDomainType = async () => {
      await axios.get(`${API}/merchant/Type`).then(async (response) => {
        setDomainTypeL(response.data.data);
        let grocId = response.data.data.find(
          (dm) =>
            dm?.domainType !== undefined &&
            dm?.domainType.toLowerCase() === "grocery shop"
        )._id;
        setGroceryId(grocId);

        setTimeout(() => {
          getCategory(grocId);
          checkGroceryMerchant(grocId);
        }, 2000);
      });
    };
    //get category for grocery merchant
    const getCategory = async (id) => {
      await axios
        .get(`${API}/category/bymerchantTypeId/${id}`)
        .then((response) => {
          setCategoryL(response.data.data);
        });
    };

    //check grocery merchant availabe or not
    const checkGroceryMerchant = async (id) => {
      await axios
        .get(`${API}/merchant/Type/bymerchantTypeId/${id}`)
        .then((res) => {
          setGroceryMerchant(
            res.data.data.some(
              (m) =>
                m.distributorId !== null &&
                m.distributorId._id === distributorId
            )
          );
        })
        .catch((err) => setGroceryMerchant(false));
    };
    const getGroupCategory = async () => {
      await axios.get(`${API}/groupcategory`).then((response) => {
        //hide veg and non-veg
        setGroupCategoryL(response.data.data);
        //.filter((item) =>item._id!=="62eb9a17e18b285f897d0777" && item._id!=="62eb9a36e18b285f897d077f"));
      });
    };

    const timer = setTimeout(() => {
      getCityName();
      getDistributor();
      getDomainType();
      getGroupCategory(); // run it, run it

      let pin = localStorage.getItem("pincode");
      pin ? setPincode(pin) : setIsPinOpen(true);

      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      refreshPage();
      // this now gets called when the component unmounts
    };
    //eslint-disable-next-line
  }, [pincode]);

  const handlePincode = async(e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");

    const config = {
      headers: {
              "Content-Type": "application/json",
              'x-token': token
            },
    }

    if (!localpin || localpin.length < 6) {
      setErrMsg(true);
      timeout = displayErrMsg(setErrMsg, timeout);
    } else {
      setPincode(localpin);
      localStorage.setItem("pincode", localpin);
      setLocalPin("");
      sessionStorage.removeItem("distributorId");
      setDistributorId("");
      localStorage.setItem("cart", []);
      setCart(getCart());
      setIsPinOpen(false);

      const pincodeData = {
        pincode: localpin
      }
  
      try {
        const res = await axios.put(`${API}/auth/edit-profile`,pincodeData ,config);
      }catch(e) {console.error(e)}
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    localStorage.setItem("cart", []);
    setCart(getCart());

    setPincode("");
    localStorage.setItem("pincode", "");
    sessionStorage.removeItem("distributorId");
    setDistributorId("");
    setLocalPin("");
    setIsPinOpen(false);
  };

  const closePinModal = () => {
    setIsPinOpen(false);
  };

  const refreshPage = () => {
    navigate("/");
    //window.location.reload("/");
  };
  // const handleValidation = () => {

    const location = useLocation();
  const showPincodePopup = () => {
    return (
      <Modal open={isPinOpen} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <Typography
            variant="h6"
            id="modal-modal-title"
            component="h6"
            sx={{ textTransform: "lowercase" }}
          >
            <EditLocationAltIcon />
            Where would you like us to deliver?
          </Typography>
          <div className="pt-2">
            <form autoComplete="nope" id="myForm">
              {/* <input
                name="pin"
                type="number"
                id="pin"
                className="form-control  h-25"
                placeholder="Enter pincode"
                autoComplete="new-password"
                onKeyPress={(e) => check(e,e.target.value)}
                 onInput={checkLength}
                onChange={(e) => setLocalPin(e.target.value)}
                required pattern="\d+"
              /> */}
              <input
                type="text"
                name="pin"
                // autoComplete="new-text"
                // autoComplete="new"
                //  autoComplete="off"
                autoComplete="new-password"
                className="form-control  h-25 autocomplete"
                placeholder="Enter pincode"
                //  list="autocompleteOff"
                //       // autoComplete="new-off"
                //  autoComplete="nope"

                // autoComplete={'new-pin' + Math.random()}
                onKeyPress={(e) => check(e)}
                onChange={(e) => setLocalPin(e.target.value)}
                maxLength="6"
              />
            </form>
            {/* <Form> */}
            {/* <Form.Field>
      <label>First Name</label>
      <input placeholder="First Name" autocomplete="new-password" />
    </Form.Field> */}
            {/* </Form> */}
          </div>

          <Typography sx={{ mt: 2, display: "flex", mx: 6 }}>
            <Button variant="contained" onClick={(e) => handlePincode(e)}>
              Submit
            </Button>
            <Button variant="contained" onClick={(e) => handleReset(e)}>
              Clear
            </Button>
            <Button variant="contained" onClick={closePinModal}>
              Close
            </Button>
          </Typography>
        </Box>
      </Modal>
    );
  };

  const shouldShowPincodePopup = location.pathname !== '/privacypolicies' && isPinOpen && showPincodePopup();
  console.log(shouldShowPincodePopup,"sdv")

  const check = (e) => {
    var char = String.fromCharCode(e.which);
    if (!/[0-9]/.test(char)) {
      e.preventDefault();
    }
  };

  // // // Firebase start
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });

  // console.log(show, notification);

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log(payload);

  //     setTimeout(function(){ setShow(false) }, 3000);
  //   })
  //   .catch((err) => console.log("failed: ", err));


  const [token_fcm, setToken] = useState('');
  const [message, setMessage] = useState('');

    const fetchToken = async () => {
      const currentToken = await getToken();
      setToken(currentToken);
      sessionStorage.setItem("FCM_TOKEN", token_fcm)
    };

    const handleMessage = async () => {
      const payload = await onMessageListener();
      console.log("payload", payload.notification, payload)
      const notificationBar = `${payload.notification.title}: ${payload.notification.body}`
      toast.info(notificationBar, { autoClose: 2000, closeOnClick: true,});
      setMessage(payload);
    };

    fetchToken();
    handleMessage();

    console.log("message", message)

  // // //   Firebase end

  return (
    <div>
      {loading ? (
        showSpinner1()
      ) : (
        <div>
          {errMsg && showErrorMsg("Please enter valid pincode", errMsg)}
          {/* {auth ? (
   <div>
     {register ? (
       <Register />
     ) : verifymobile ? (
       <VerifyMobile />
     ) : (
       <Login />
     )}
   </div>
 )
  : ( */}

          <MerchantProvider>
            {/* {isPinOpen && showPincodePopup()} */}

            {shouldShowPincodePopup && showPincodePopup()}
          
            {/* <Navbar /> */}
            {/* <NavBarHeader /> */}
            <NavBarTop />

            <ScrollTop>
              {/* {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
          
        />
      ) : (
        <></>
      )}
      <Notifications /> */}

              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/about" element={<About />} />
                {/* <Route path="/index" element={<Index />} /> */}
                <Route path="/restmarchant" element={<RestMarchant />} />
                <Route path="/agrimarchant" element={<AgriMarchant />} />
                <Route path="/medimarchant" element={<MediMarchant />} />
                <Route path="/DairyMarchant" element={<DairyMarchant />} />
                <Route path="/ramproduct" element={<RamProduct />} />
                <Route path="/sundarproducts" element={<SundarProducts />} />
                <Route path="/govindproducts" element={<GovindProducts />} />
                <Route path="/amulproducts" element={<AmulProducts />} />

         <Route
           path="/privacypolicies"
           element={<PrivacyPolicy />}
         />
                {/* <Route path="/privacypolicies" element={<PrivacyPolicy />} /> */}
                <Route path="/verifymobile" element={<VerifyMobile />} />
                <Route path="/merchantList" element={<Merchant />} />
                <Route path="/marchant" element={<Marchant />} />
                <Route path="/productList" element={<ProductList />} />
                <Route path="/product/:id" element={<ProductDetailsData />} />
                <Route path="/medicalPrescription" element={<MedicalPrescription />} />
                {/* <Route path="/productDetailsData" element={<ProductDetailsData/>} /> */}
                <Route path="/bestofferAll" element={<BestOfferall />} />
                <Route path="/about" element={<About />} />
                {/* <Route path="/index" element={<Index />} /> */}
                <Route path="/Faq" element={<Faq />} />
                <Route path="/shoppingcart" element={<Cart />} />
                <Route path="/myprofile" element={<MyProfile />} />
                {/* <Route path="/myorder" element={<MyOrder />} /> */}
                <Route path="/myorder" element={<MyOrderDisplay />} />
                <Route path="/orderplaced" element={<OrderPlaced />} />
                <Route path="/companycontact" element={<Companycontact />} />
                <Route path="/franchise" element={<Franchise />} />
                <Route path="/termsandcondition" element={<TC />} />
                <Route
                  path="/distributercontact"
                  element={<Distributercontact />}
                />
                <Route path="/merchantproduct" element={<MerchantProduct />} />
                <Route
                  path="/merchantprodetails"
                  element={<MerchantProDetails />}
                />
                <Route path="/category" element={<MyCategory />} />
                <Route path="/notification" element={<MyNotification />} />
                <Route path="/shipping" element={<AddAdress />} />
                <Route path="/domaintype" element={<DomainType />} />
                <Route path="/orderhistory" element={<OrderHistory />} />
                <Route path="/CancelOrder" element={<CancelOrder />} />
                <Route path="/OrderDetails" element={<OrderDetails />} />
                <Route path="/PaymentMethod" element={<PaymentMethod />} />
                <Route path="/seeall" element={<SeeAll />} />
                <Route
                  path="/vieworderdetails"
                  element={<ViewOrderDetails />}
                />
                <Route
                  path="/seeallcategarious"
                  element={<Seeallcategarious />}
                />
                <Route
                  path="/seeallRecommended"
                  element={<SeeallRecommended />}
                />
                <Route
                  path="/seeallgroupcategories"
                  element={<SeeallGroupcategories />}
                />
                <Route
                  path="/todaydealsseeall"
                  element={<Todaydealsseeall />}
                />
                <Route path="/categories" element={<DisplayCategories />} />
                <Route
                  path="/groupcategories"
                  element={<DisplayGroupCategories />}
                />
                <Route
                  path="/merchantNotFound"
                  element={<MerchantNotAvailable />}
                />
                <Route path="/*" element={<PageNotFound />} />
              </Routes>
            </ScrollTop>
            <div>
               <ToastContainer
                 position="bottom-right"
                 autoClose={2000}
                 hideProgressBar
                 newestOnTop={false}
                 closeOnClick={true}
                 rtl={false}
                 draggable
                 pauseOnHover
                 theme="colored"
                //  transition="Bounce"
               />
             </div>
            <Footer />
          </MerchantProvider>

          {/* )} */}
        </div>
      )}
    </div>
  );
}

export default App;
//    let n=document.getElementById('pin').innerText;
//    const re = /^[0-9\b]+$/;
//     if (n === '' || re.test(n)) {
//       alert(n)
//     }
//     else{
//       document.getElementById('pin').innerText=n.slice(0,n.length-1)
//     }
//   //  if ((event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 13) {
//   //   // do something with this information
//   //   return true
//   // }
//   // return false;
// };
// const validPin=(pin)=> {
//   var isNumber = /^\d+$/.test(pin) && (pin.length == 4 || pin.length == 6)
//   return isNumber? true :false
//   }

//   const check = (evt) => {

//     // let allow=((e.key === "Backspace")||(e.key==='1')||(e.key==='2')||(e.key==='3')||(e.key==='4')||(e.key==='5')||(e.key==='6')||(e.key==='7')||(e.key==='8')||(e.key==='9')||(e.key=='0'))
//     // if(!allow)
//     // {
//     //    e.target.tabIndex - 2;

//     // }
//     var charCode = (evt.which) ? evt.which : evt.keyCode;

//     if (charCode != 46 && charCode > 31 && charCode != 45 && charCode != 43
//       && (charCode < 48 || charCode > 57))
//       alert(false)
//        return false;

//     alert(true)
//     return true;

// //alert((e.which) ? e.which : e.keyCode)
//     // var pat1=/^[0-9]{1,6}$/;
//     // if(!pat1.test(pin))
//     // {
//     //   alert('no');
//     // }
//     // alert('yes')

//     // var charCode = (e.which) ? e.which : e.keyCode
//     // if(charCode >=48 && charCode<=57)
//     // return true;

//     // return false;
//     // if (charCode > 31 && (charCode < 48 || charCode > 57))
//     //    return false;

//     //  if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 13) {
//     //   // do something with this information
//     //   return true
//     // }
//     // return false;
//     //Check Charater
//     // let unicode = e.charCode ? e.charCode : e.keyCode;
//     // // // console.log(unicode)
//     //  if (value.indexOf(".") != -1)return false;
//     //  if (unicode == 46) return false;
//     // if(unicode == 43)return false;
//     // if(unicode == 45)return false;
//     // if (unicode != 8)return false;
//     //   if (unicode < 48 || unicode > 57) return false;

//     // const pattern = /^[0-9]$/;
// //  const pattern=/^(0|[1-9][0-9]*)$/;
// //     // && !(e.charCode==43 || e.charCode==45 ||e.charCode==46)
// //     return pattern.test(e.key )
//    // return (e.charCode>=48 && e.charCode<=57)
//   };
//   const checkLength = () => {
//     let fieldLength = document.getElementById("pin").value.length;
//     //Suppose u want 4 number of character
//     if (fieldLength <= 6 || fieldLength == null) {
//       return true;
//     } else {
//       var str = document.getElementById("pin").value;
//       str = str.substring(0, str.length - 1);
//       document.getElementById("pin").value = str;
//     }
//   };
// const JqueryCode=()=>{
//   // setTimeout(function(){$('.pincode').val('')},100)
//   // // $('input').attr('autocomplete', 'off')
//   // $('.form-control').autocomplete( "disable" );
//   // $('.form-control').autocomplete({disabled: true});
//   // $(".pincode").
//   $("input:text").attr("autocomplete","off");

// }

//   const getCategory = async () => {
//     // await axios.get(`${API}/category`).then((response) => {
// // console.log(`${API}/category/bymerchantTypeId/${groceryId}`)
//     await axios
//      .get(`${API}/category/bymerchantTypeId/${groceryId}`)
//       // .get(`${API}/category/bymerchantTypeId/62ea4e2a5988c24ef81b097a`)
//       .then((response) => {
//         setCategoryL(response.data.data);
//       });

//   };
// if(window.location.href.split('/')[3]=="privacypolicy"))
// {

// }
