import React, { Fragment } from "react";
import "./About.css";

function About() {
  return (
    <Fragment>
      <main>
        {/* <section className=""> */}
        {/* <div class="about"><img src="assets/images/Banners/sample-1.jpg" width="100%" /></div> */}
        {/* </section> */}
        <section className="about_us_container">
          <div className="about_us_text">
            <h3 className="About_us_main_heading">About Us</h3>
            <p className="about_us_content ">
              We believe there is a better way to do marketing. A more valuable
              less invasive way where customers are earned rather than bought.
              We're obsessively passionate about it, and our mission is to help
              people achieve it. We believe the best way deliver a great user
              experience is by deeply understanding what people want & love.
              Then deliver the best quality product. That's what makes users
              happy & loyal. Our app strives to deliver the tools & support that
              helps to deliver great experience. We want our customer to be
              happy, so then our customer are happy & that makes us happy.
            </p>
          </div>
          <div className="about_img">
            <img src="/assets/images/orbitlog.png" alt="" className="about_us_image" />
          </div>
        </section>
      </main>
    </Fragment>
  );
}

export default About;
