import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar2Dropdown() {
    return (
        <NavDropdown
            // id="nav-dropdown-dark-example"  
            title="Contacts"
            menuVariant="light"
        >
            <NavDropdown.Item href="#/distributercontact">Distributor Contact</NavDropdown.Item>
            <NavDropdown.Item href="#/companycontact">Comapany Conatct</NavDropdown.Item>
            <NavDropdown.Item href="#/franchise">Apply for Franchise</NavDropdown.Item>
        </NavDropdown>

    );
}

export default NavBar2Dropdown;