import React from 'react'

const MyNotification = () => {
    return (  <div className="mx-auto ms-3 crd bg-white rounded ">

    <div className="px-5 pb-5 container d-block justify-content-center ">
        <h4>Notification</h4>
      <div className=" mx-auto pb-3 img-thumbnail w-25 h-25 text-center ">
        <h5 style={{ marginTop: "10px" }}>We're sorry</h5>
   

      <div className="text-center  ">

        <div className="row ">
        <h6>Notification Not Found</h6>
          <img src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png" alt="notification not found"  style={{margin:"auto"}} />
        </div>
      </div>
      </div>
    </div>
    <div></div>
  </div>
        // <>
        //     <div className="container d-block justify-content-center">
        //         <h2 className='mb-4'>My Notifications</h2>
        //         <div className="shadow p-2 mb-3 bg-body rounded">
        //             <div className="m-0 px-3">
        //                 <h5 className='m-0'>Samso aliao</h5>
        //                 <p className='m-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi aspernatur, rem laboriosam perspiciatis labore?</p>
        //                 <p className='fw-light fs-6 text-end m-0'>just now</p>
        //             </div>
        //         </div>
        //         <div className="shadow p-2 mb-3 bg-body rounded"> 
        //             <div className="m-0 px-3">
        //                 <h5 className='m-0'>John Silvester</h5>
        //                 <p className="m-0">+20 Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, dolores.</p>
        //                 <p className='fw-light fs-6 text-end m-0'>just now</p>
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
}

export default MyNotification