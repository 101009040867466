import React, { useContext, useState } from "react";
import Draggable from 'react-draggable';
import { API } from "../config/api";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { FormGroup } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { StateContext } from "../context/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { showLoader } from "../compenent/helpers/loading";
import { showErrorMsg, showSuccessMsg } from "./helpers/message";
import { useNavigate,Link as ALink } from "react-router-dom";
const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;

const RegisterPopup = (props) => {
  const { registerInfo, setRegisterInfo,setIsOpen } = useContext(StateContext);
  const { currentPage, setCurrentPage } = useContext(StateContext);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  };

  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    },1000);
    return timeout;
  };
  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please enter First Name"),
    lastname: Yup.string().required("Please enter Last Name"),
    email: Yup.string()
      .required("Please enter Email")
      .email("Please enter valid Email ID"),
    mobile: Yup.string()
      .required("Please enter Mobile number")
      .matches(phoneRegex, "Please enter valid Mobile Number"),
    password: Yup.string()
      .required("Please enter Password")
      .min(6, "Password must be at least 6 characters"),
  });
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (user) => {
      setLoading(true);
      const userdata = {
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        mobile: user.mobile,
        role: "USER",
        password: user.password,
      };
      const config = { headers: { "Content-Type": "application/json" } };
      setErrMsg(false);
      setSuccessMsg(false);
      const res = await axios
        .post(`${API}/auth/register`, userdata, config)
        .then((res) => res)
        .catch((err) => {
          setErrMsg(true);
          setMsg(err.response.data.message);
          setLoading(false)
          timeout = displayErrMsg(setErrMsg, timeout);
        });
      if (res) {
        // setSuccessMsg(true);
        // setMsg(res.data.message);
       
        // timeout =  displaySuccessMsg(setSuccessMsg, timeout);
        // if(timeout)
        // {
          setRegisterInfo({
            otp: res.data.data.addUser.otp,
            mobile: res.data.data.addUser.mobile,
          });
          //setRegisterID(res.data.data.addUser._id)
          setCurrentPage(2);
        // }
      
        
      }
      // const verifyData={mobile:user.mobile,otp:res.data.data.addUser.otp}
      // await axios.post(`${API}/auth/verify-mobile`,verifyData,config).then((res)=>console.log(res.data))
      //   const  response=await axios.post(`${API}/auth/register`,userdata,config).then((res)=>res.data)
      //   if(response)
      //   {
      //     const verifyData={mobile:user.mobile,otp:response.addUser.otp}
      //     await axios.post(`${API}/auth/verify-mobile`,verifyData,config).then((res)=>console.log(res.data))

      //   }
      //   await axios.post(`${API}/auth/register`,userdata,config).then((res)=>{

      //     // console.log(res.data);setSuccessMsg(true)
      //     //   setMsg(res.data.message)
      //    // setsetCurrentPage(0)
      // })

      //   .catch((err)=> {setErrMsg(true);setMsg(err.response.data.message)})
      //   console.log(response)
      //   if(response.messageCode===201)
      //   {
      //     setSuccessMsg(true)
      //     setMsg(response.message)
      //   }
      //   else if(response.code===405)
      //   {
      //     // setErrMsg(true)
      //     // setMsg(response.message)
      //     alert("bh")
      //   }

      //   else{
      //     setSuccessMsg(false)
      //     setErrMsg(true)
      //     setMsg("Internal Server Error")

      //   }
      // setErrMsg(true)
    },
  });
  // const showErrorMessage = (msg) => {
  //   return (
  //     <div class="alert alert-danger" role="alert">
  //  {msg}
  //   </div>
  //   )
  // }
  // const showSuccessMessage = (msg) => {
  //   return (
  //     <div class="alert alert-success" role="alert">
  //  {msg}
  //   </div>
  //   )
  // }
  return (
    <div className="popup-box">
        {successMsg && showSuccessMsg(msg, successMsg)}
               
                  {errMsg && showErrorMsg(msg, errMsg)}
          <Draggable>
     <div>
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
        <div className=" d-flex w-100">
          <Grid container component="main">
            <CssBaseline />
            <Grid
              item
              sm={4}
              component={Paper}
              elevation={6}
              sx={{ backgroundColor: "#003C7E", color: "white" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ color: "white", pb: 2 }}
                >
                  Looks like you're new here!
                </Typography>
                <Typography component="p" variant="body2">
                  Sign up with your mobile number to get started
                </Typography>
              </Box>
              <center>
                <img
                  src={
                    "https://assets.magedelight.com/media/catalog/product/o/t/otp-notification.png"
                  }
                  className="w-75 mb-3"
                  alt={"welcome image"}
                  loading="lazy"
                />
              </center>
            </Grid>

            <Grid item sm={8} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 4,
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                      {loading && showLoader()}
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  autocomplete="off"
                >
                
          
                  <TextField
                    variant="standard"
                    id="firstname"
                    name="firstname"
                    type="text"
                    label="Enter First Name"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.firstname && formik.touched.firstname}
                    helperText={
                      formik.errors.firstname && formik.touched.firstname
                        ? formik.errors.firstname
                        : ""
                    }
                    autoFocus
                    inputProps={{
                      autocomplete: 'new-firstname',
                      form: {
                        autocomplete: 'off',
                      },
                      style: { textTransform:'lowercase' }
                    }}
                  />
                  <TextField
                    variant="standard"
                    id="lastname"
                    name="lastname"
                    type="text"
                    label="Enter Last Name"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lastname && formik.touched.lastname}
                    helperText={
                      formik.errors.lastname && formik.touched.lastname
                        ? formik.errors.lastname
                        : ""
                    }
                    inputProps={{
                      autocomplete: 'new-lastname',
                      form: {
                        autocomplete: 'off',
                      },
                      style: { textTransform:'lowercase' }
                    }}
                  />
                  <TextField
                    variant="standard"
                    id="email"
                    name="email"
                    type="text"
                    label="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email && formik.touched.email}
                    helperText={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ""
                    }
                    inputProps={{
                      autocomplete: 'new-email',
                      form: {
                        autocomplete: 'off',
                      },
                      style: { textTransform:'lowercase' }
                    }}
                  />
                  <TextField
                    variant="standard"
                    id="mobile"
                    name="mobile"
                    type="text"
                    label="Enter mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile && formik.touched.mobile}
                    helperText={
                      formik.errors.mobile && formik.touched.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                    inputProps={{
                      autocomplete: 'new-mobile',
                      form: {
                        autocomplete: 'off',
                      },
                      style: { textTransform:'lowercase' }
                    }}
                  />

                  <TextField
                    variant="standard"
                    id="password"
                    name="password"
                    label="Enter Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.password && formik.touched.password}
                    helperText={
                      formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ""
                    }
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                      style: { textTransform:'lowercase' }
                    }}
                  />

                  <p className="policy">
                    By continuing, you agree to OrbitMart's{" "}
                        
                    <ALink className="lk" to="/termsandcondition" onClick={()=>setIsOpen(false)} >
                    Terms of Use 
            </ALink>{" "}
                    and{" "}
                    <ALink className="lk" to="/privacypolicy" target="_blank" onClick={()=>setIsOpen(false)} >
              Privacy Policy
            </ALink>
                  .  
                  </p>
                  <Box sx={{ mt: 2 }}>
                    <div className="card ">
                      <button className="btn-sm btn-warning " type="submit">
                        Sign Up
                      </button>
                    </div>
                  </Box>

                  <Grid container sx={{ justifyContent: "center" }}>
                    {/* <Grid item xs>
                    <Link  variant="body2" sx={{ mx: "auto" }} onClick={()=>setCurrentPage(0)}>
                      Forgot password?
                    </Link>
                  </Grid> */}
                    <Grid item>
                      <Link
                        variant="body2"
                        onClick={() => setCurrentPage(0)}
                        sx={{ cursor: "pointer" }}
                      >
                        {"Already Register? Login "}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>{" "}
      </div>
     
     </div>
     </Draggable>
    </div>
  );
};

export default RegisterPopup;
