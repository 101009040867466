import React,{useContext, useState,useEffect} from "react";
import "./Companycontact.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import { useFormik } from "formik"
import axios from "axios";
import { API } from "../config/api";
import { showErrorMsg,showSuccessMsg } from "./helpers/message";
import { showLoader } from "./helpers/loading";
import { StateContext } from "../context/ContextProvider";

const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; 
const emailRegex =/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Companycontact = () => {

  

  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
const {pincode,cityName}=useContext(StateContext)
const [map,setMap]=useState("")
  useEffect(()=>{

    setMap('https://maps.google.com/maps?q='+cityName+'&t=&z=13&ie=UTF8&iwloc=&output=embed')
  },[])


  
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }
  const displaySuccessMsg=(setSuccessMsg, timeout)=> {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    },1000);
    return timeout;
  }
  const validationSchema = Yup.object({
    name:Yup.string().required("Please enter Name").min(4,"Name must be at least 4 characers"),
    email:Yup.string().required("Please enter Email").matches(emailRegex,"Please enter valid Email"),
    mobile:Yup.string().required("Please enter Mobile No").matches(phoneRegex,"please enter valid Mobile No"),
    message: Yup.string().required("Please enter Message").min(20, "Message must be at least 20 characters"),
  });
  
  
  const formik = useFormik({
    initialValues: {name:"", email: "",mobile:"", message: "" },
    validationSchema: validationSchema,
    onSubmit: async (company) => {
      setErrMsg(false);
   
        setLoading(true);

      let data = {
        name: company.name,
        email: company.email,
        mobile:company.mobile,
        message:company.message,
    
      };
       const config = { headers: { "Content-Type": "application/json" } };
   
      await axios.post(`${API}/companyContact`,data,config).then((res)=>{
     
        setMsg(res.data.message)
if(res.data.status=="SUCCESS"){
  setSuccessMsg(true)
   
  timeout = displaySuccessMsg(setSuccessMsg, timeout);
}
else{
  setErrMsg(true)
   
  timeout = displayErrMsg(setErrMsg, timeout);
}
setLoading(false)
      }
      
      )
   
    }})
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <div>
      <main>
        {/* <section className="">
          <div className="company_Bg">
            <img src="assets/images/probanner.jpg" />
          </div>
        </section> */}
        <section className="contact_details">
          <div className="row">
            <div className="col-lg-6">
              {/* <form noValidate autoComplete="off">
                <TextField id="standard-basic" label="Name" />
                <TextField id="standard-basic" label="Email" />
                <TextField id="standard-basic" label="Phone Number" />
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Empty"
                />
               
                <div className="">
                  <Button variant="contained">Submit</Button>
                </div>
              </form> */}
                 <form
                noValidate
                autoComplete="off"
                style={{
                  margin: "auto",
                  width: "75%",
                  backgroundColor: "whitesmoke",
                  borderRadius: "25px",
                  marginBottom: "10px",
                }}

                onSubmit={formik.handleSubmit}
              >
                <fieldset
                  style={{
                    // border: "1px solid #003c7e",
                    padding: "40px 55px",
                  }}
                >
                  <h3>
                    <b style={{ color: "#003c7e" }}>Company Contact</b>
                  </h3>
                  {successMsg && showSuccessMsg(msg, successMsg)}
                  {errMsg && showErrorMsg(msg, errMsg)}
                  {loading && showLoader()}
                  <TextField
                    id="name"
                    label="name"
                    variant="standard"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name && formik.touched.name}
                    helperText={
                      formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : ""
                    }
                    autoFocus
                    inputProps={{
                      autocomplete: 'new-name',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }}
                  />
                  <br />
                  <TextField
                   id="email"
                   label="email"
                   variant="standard"
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   error={formik.errors.email && formik.touched.email}
                   helperText={
                     formik.errors.email && formik.touched.email
                       ? formik.errors.email
                       : ""
                   }
                  
                   inputProps={{
                     autocomplete: 'new-new-email',
                     form: {
                       autocomplete: 'off',
                     },
                      style: { textTransform:'lowercase' }
                   }}
                  />
                  <br />
                  <TextField
                  id="mobile"
                  label="mobile"
                  variant="standard"
                  type="text"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.mobile && formik.touched.mobile}
                  helperText={
                    formik.errors.mobile && formik.touched.mobile
                      ? formik.errors.mobile
                      : ""
                  }
      
                  inputProps={{
                    autocomplete: 'new-mobile',
                    form: {
                      autocomplete: 'off',
                    },
                     style: { textTransform:'lowercase' }
                  }}
                  />
                  <br />
             
                
                       <TextField
                                   id="message"
                    label="message"
                    variant="standard"
                    multiline
          maxRows={4}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.message && formik.touched.message}
                    helperText={
                      formik.errors.message && formik.touched.message
                        ? formik.errors.message
                        : ""
                    }
                   
                    inputProps={{
                      autocomplete: 'new-message',
                      form: {
                        autocomplete: 'off',
                      },
                       style: { textTransform:'lowercase' }
                    }}
                  />
                  <br />
           
                  <Button variant="contained"  type="submit">Submit</Button>
                </fieldset>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="contact_form_container">
                <iframe
                  className="responsive-iframe"
                  src={map}
                  // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29775.03827690716!2d79.12187615!3d21.1173584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1642501007317!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section className="company_det">
          <div className="row Company-container">
            <div className="col-md-3 ">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fa fa-mobile" style={{ color: "green" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "green" }}>Mobile No.</h4>
                        <p>9007957009</p>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fas fa-envelope" style={{ color: "red" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "red" }}>Email Id</h4>
                        <a className="text-lowercase">growupsindhu@gmail.com</a>
                      
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardContent>
                  <Typography>
                    <div className="d-flex">
                      <div className="contact_Email">
                        <i className="fas fa-clock" style={{ color: "blue" }}></i>
                      </div>
                      <div className="contact_email">
                        <h4 style={{ color: "blue" }}>Timing</h4>
                        <p>8AM to 12PM</p>
                        <a>12/7</a>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Companycontact;
