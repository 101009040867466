import React, { useState, useContext, useEffect } from "react";
import { API } from "../config/api";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { StateContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";
// import { TextField } from "@material-ui/core";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import CssBaseline from "@mui/material/CssBaseline";
// import Button from "@mui/material/Button";
// import { AiOutlineConsoleSql } from "react-icons/ai";
// import { OrdersNotFound } from "./OrdersNotFound";

const MyOrderDisplay = () => {
  const userId = window.localStorage.getItem("userId");

  const { setMyOrder, myOrder, setOrderInfo } = useContext(StateContext);
  const [findOS, setFindOS] = useState([]);

  useEffect(() => {
    const getUsersOrders = async () => {
      try {
        const res = await axios.get(`${API}/order/user/${userId}`);
        setMyOrder(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUsersOrders();
    //eslint-disable-next-line
  }, []);

  const OrderStatus = [...new Set(myOrder.map((os) => os.orderStatus))];

  const [text, setText] = useState("");
  const [orders, setOrders] = useState(myOrder);

  const handleCategory = (e) => {
    let updateOS = findOS;
    if (e.target.checked) {
      updateOS.push(e.target.value);
    } else {
      const index = updateOS.indexOf(e.target.value);
      if (index !== -1) {
        updateOS.splice(index, 1);
      }
    }
    setFindOS(updateOS);
    const matches = myOrder.filter((or) => findOS.includes(or.orderStatus));

    setOrders(matches);
  };
  const handleSearch = (e) => {
    setFindOS([]);
    if (!text) {
      setOrders(myOrder);
    } else {
      const matches = myOrder.filter(
        (or) => or.orderNo.toLowerCase().indexOf(text.toLowerCase()) !== -1
      );
      setOrders(matches);
    }
    e.preventDefault();
  };

  let navigate = useNavigate();
  const handleOrder = (order) => {
    setOrderInfo(order);
    console.log(order);
    navigate("/OrderDetails");
  };

  const handleReset = () => {
    setOrders(myOrder);
    setText("");
  };
  return (
    <div className=" m-4 mb-5 crd ms-5 me-5  ">
      <div className="jumbotron mb-auto ">
        <div className=" d-flex justify-content-between">
          <h4>My Orders</h4>
        </div>

        <hr className="new2"></hr>

        <div className="row bg-secondary crd">
          <div
            className="col-md-3 border-right bg-white bs  pt-2 px-3"
            style={{ height: "350px" }}
          >
            <h5 className="mb-3 mt-3">
              Filters
              {/* <span className='fa fa-sliders'></span> */}
            </h5>
            <h6 className="mb-3 mt-3">Order Status</h6>

            <div className=" bg-light ">
              {OrderStatus &&
                OrderStatus.map((os, index) => (
                  <div key={index} className="form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="category"
                      value={os}
                      id="flexCheckChecked"
                      checked={findOS.includes(os) ? true : false}
                      onChange={handleCategory}
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexCheckChecked"
                    >
                      <small className="text-capitalize">
                        {os.toLowerCase()}
                      </small>
                    </label>
                  </div>
                ))}
              {/* {categories &&
							categories.map(c => (
								<div key={c._id} className='form-check'>
									<input
										className='form-check-input'
										type='checkbox'
										name='category'
										value={c._id}
										id='flexCheckChecked'
										checked={categoryIds.includes(c._id)}
										onChange={handleCategory}
									/>
									<label
										className='form-check-label'
										htmlFor='flexCheckChecked'
									>
										{c.category}
									</label>
								</div>
							))} */}
            </div>
          </div>
          <div className="col-md-9  scroll-bar bg-secondary ps-5">
            <div className="search-box ">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  name="search"
                  placeholder="Search by orderNo"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
            {orders.length > 0 ? (
              orders.map((or) => (
                <div
                  className="row bs bg-white p-2 my-2"
                  onClick={(e) => handleOrder(or)}
                >
                  <div className=" d-flex justify-content-between">
                    <Typography
                      component="h1"
                      variant="body1"
                      sx={{
                        color: "#003C7E",
                        pb: 2,
                        textTransform: "uppercase",
                      }}
                    >
                      {or.orderNo}
                    </Typography>

                    <Typography
                      component="h1"
                      variant="body1"
                      sx={{ color: "#003C7E", pb: 2 }}
                    >
                      {or.productDetails
                        .map((q) => q.quantity)
                        .reduce((a, b) => a + b)}
                      no of items
                    </Typography>
                  </div>
                  <div className="row">
                    <div className="d-flex justify-content-around">
                      <div className="p-2">
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Order on
                        </Typography>
                        <Typography component="p" variant="body2">
                          {new Date(or.createdAt).toLocaleString("en-US", {
                            weekday: "short",
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                        </Typography>
                      </div>
                      <div className="p-2">
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Order Status
                        </Typography>
                        <Typography
                          component="p"
                          variant="body2"
                          sx={{ color: "#28a745" }}
                        >
                          {or.orderStatus}
                        </Typography>
                      </div>
                      <div className="p-2">
                        {" "}
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Amount
                        </Typography>
                        <Typography component="p" variant="body2">
                          ₹ {or.totalPrice}
                          {/* {(
                            or.totalPrice -
                            or.productDetails
                              .map((pr) => {
                                if (pr.productId.discountType == "FIXED") {
                                  return parseInt(
                                    pr.productId.customerDiscount
                                  );
                                } else {
                                  return (
                                    (parseInt(pr.productId.itemPrice) *
                                      parseInt(pr.productId.customerDiscount)) /
                                    100
                                  );
                                }
                              })
                              .reduce((a, b) => a + b) +
                            (or.totalPrice *
                              parseInt(or.productDetails[0].productId.gst)) /
                              100 +
                            0
                          ).toFixed(0)} */}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {/*+(or?.productDetails
                            ?.map((pr) => {
                              return parseInt(pr?.productId?.orbitMartCommission);
                            })
                            .reduce((a, b) => a + b)) {myOrder
        .sort(
          (date1, date2) =>
            new Date(date2.createdAt) - new Date(date1.createdAt)
        )
        .map((or) => (
          <Grid
            container
            component="main"
            className="justify-content-center mb-2"
          >
            <Grid
              item
               sm={10}
              md={8}
              lg={6}
              component={Paper}
              elevation={10}
              sx={{ backgroundColor: "#003C7E", color: "white" }}
              onClick={(e) => handleOrder(or)}
            >
              <Box
                sx={{
                  my: 2,
                  mx: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: 1,
                }}
              >
                <Typography
                  component="h1"
                  variant="body1"
                  sx={{ color: "#9eea00", pb: 2, textTransform: "uppercase" }}
                >
                  {or.orderNo}
                </Typography>
                <Typography
                  component="h5"
                  variant="body1"
                  sx={{ color: "white", textTransform: "lowercase", pb: 2 }}
                >
                  {or.productDetails
                    .map((q) => q.quantity)
                    .reduce((a, b) => a + b)}
                  no of items
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 1,
                  display: "flex",
                  flexDirection: "row",

                  columnGap: "auto",
                }}
              >
                <Box
                  sx={{
                    my: 2,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="body1"
                    sx={{ color: "lightgrey", pb: 2 }}
                  >
                    Order on
                  </Typography>
                  <Typography component="p" variant="body2">
                    {new Date(or.createdAt).toLocaleString("en-US", {
                      weekday: "short",
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    my: 2,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="body1"
                    sx={{ color: "lightgrey", pb: 2 }}
                  >
                    Order Status
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    sx={{ color: "#9eea00" }}
                  >
                    {or.orderStatus}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    my: 2,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                 
                </Box>
                <Box
                  sx={{
                    my: 2,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="body1"
                    sx={{ color: "lightgrey", pb: 2 }}
                  >
                    Amount
                  </Typography>
                  <Typography component="p" variant="body2">
                    ₹{" "}

{((or.totalPrice)-(or.productDetails.map((pr)=>{
  if(pr.productId.discountType=='FIXED')
  {
    return  parseInt(pr.productId.customerDiscount)
  }
  else{
    return  (parseInt(pr.productId.itemPrice)*parseInt(pr.productId.customerDiscount))/100
  }
}).reduce((a,b)=>a+b))+ (((or.totalPrice*parseInt(or.productDetails[0].productId.gst))/100))+20).toFixed(0)}

           
                  </Typography>
                  </Box>
              </Box>
            </Grid>
          </Grid>
        ))} */}
                </div>
              ))
            ) : (
              <div className="mx-auto ms-3 crd  rounded text-center ">
                <div className="px-5 pb-5 ">
                  <div className=" flex-grow-1 bd-highlight  pb-3">
                    <h4 style={{ marginTop: "10px" }}>
                      Sorry, no results found
                    </h4>
                  </div>

                  <div className=" text-center ">
                    <div className="flex mb-2">
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/business-strategy-planning/64/j-34-512.png"
                        alt="orders not found"
                        style={{ height: "100px", width: "100px" }}
                      />
                      <p>Edit search or go back to My Orders Page</p>

                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handleReset}
                      >
                        Go to My Orders
                      </button>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderDisplay;
