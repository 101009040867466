import React from 'react';
import './Pc.css';

const PrivacyPolicy = () => {
	const domain = 'orbitmart.co.in';
	const handleClick = () => {
		// window.location.href = 'orbitmart.co.in';
		window.open('https://orbitmart.co.in', '_blank');
	};
	
	return (
		<div className="Privacy_policy_container mx-auto w-75 bs crd">
			<div className="card mx-5 text-justify w-auto">
				<h4 className="text-center text-black pt-3">Privacy Policy</h4>
				<h5 className="text-black text-center py-3">Growup Sindhu Technology & Solutions Pvt. Ltd.</h5>
				<h6 className="text-center pb-2">
					Website - <b className="text-lowercase"  style={{ cursor: 'pointer' }} onClick={handleClick}>{domain}</b>
				</h6>
				<h6 className="General_terms_condition_heading text-left py-3">
					Guidelines, General Terms & Conditions, Policies:
				</h6>
				<p>
					<small className="py-5">
					This Document Is An Electronic Record In Terms Of Information
              Technology Act, 2000 And Rules There Under As Applicable And The
              Amended Provisions Pertaining To Electronic Records In Various
              Statutes As Amended By The Information Technology Act, 2000. This
              Electronic Record Is Generated By A Computer System And Does Not
              Require Any Physical Or Digital Signatures.
					</small>
					<br />
					<br />
					<small className="py-5">
					This Document Is Published In Accordance With The Provisions Of
              Rule 3 (1) Of The Information Technology (Intermediaries
              Guidelines) Rules, 2011 That Require Publishing The Rules And
              Regulations, Privacy Policy And Terms Of Use For Access Or Usage
              Of Www.Orbitmarts.Com Website
					</small>
					<br />
					<br />
					<small className="py-5">
					The Domain Name Www.Orbitmarts.Com (Hereinafter Referred To As
              "Website") Is Owned By Growup Sindhu Technology & Solutions
              Private Limited A Company Incorporated Under The Companies Act,
              1956 With Its Registered Office At H No.3158 Shivshakti-2, Near
              Kudaleshwar Mandir, Kudal, Dist- Sindhudurg,
              Maharashtra-416520.India. Your Use Of The Website And Services And
              Tools Are Governed By The Following Terms And Conditions ("Terms
              Of Use") As Applicable To The Website Including The Applicable
              Policies Which Are Incorporated Herein By Way Of Reference. If You
              Transact On The Website, You Shall Be Subject To The Policies That
              Are Applicable To The Website For Such Transaction. By Mere Use Of
              The Website, You Shall Be Contracting With Growup Sindhu
              Technology & Solutions Private Limited And These Terms And
              Conditions Including The Policies Constitute Your Binding
              Obligations. For The Purpose Of These Terms Of Use, Wherever The
              Context So Requires "You" Or "User" Shall Mean Any Natural Or
              Legal Person Who Has Agreed To Become A Customer On The Website By
              Providing Registration Data While Registering On The Website As
              Registered User Using The Computer Systems. Orbit Mart Allows The
              User To Surf The Website Or Making Purchases Without Registering
              On The Website. The Term "We", "Us", "Our" Shall Mean Growup
              Sindhu Technology & Solutions Private Limited.
					</small>
					<br />
					<br />
					<small className="py-5">
					When You Use Any Of The Services Provided By Us Through The
              Website, Including But Not Limited To, (E.G., Product Reviews,
              Merchant Reviews), You Will Be Subject To The Rules, Guidelines,
              Policies, Terms, And Conditions Applicable To Such Service, And
              They Shall Be Deemed To Be Incorporated Into This Terms Of Use And
              Shall Be Considered As Part And Parcel Of This Terms Of Use. We
              Reserve The Right, At Our Sole Discretion, To Change, Modify, Add
              Or Remove Portions Of These Terms Of Use, At Any Time Without Any
              Prior Written Notice To You. It Is Your Responsibility To Review
              These Terms Of Use Periodically For Updates / Changes. Your
              Continued Use Of The Website Following The Posting Of Changes Will
              Mean That You Accept And Agree To The Revisions. As Long As You
              Comply With These Terms Of Use, We Grants You A Personal,
              Non-Exclusive, Nontransferable, Limited Privilege To Enter And Use
              The Website.
					</small>
					<br />
					<br />
					<small>
						<b className="Privacy_policy_bolded_content py-5">
						ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
              By Impliedly Or Expressly Accepting These Terms Of Use, You Also
              Accept And Agree To Be Bound By Orbit Mart Policies As Amended
              From Time To Time.{" "}
						</b>
					</small>
				</p>
				<h6 className="Privacy_policy_subheading_text text-left py-3">Customer’s Eligibility</h6>
				<p>
					<small className="py-5">
					Use Of The Website Is Available Only To Persons Who Can Form
              Legally Binding Contracts Under Indian Contract Act, 1872. Persons
              Who Are "Incompetent To Contract" Within The Meaning Of The Indian
              Contract Act, 1872 Including Minors, Undischarged Insolvents Etc.
              Are Not Eligible To Use The Website. If You Are A Minor I.E.,
              Under The Age Of 18 Years, You Shall Not Register As A User Of The
              Orbit Mart Website And Shall Not Transact On Or Use The Website.
              As A Minor If You Wish To Use Or Transact On Website, Such Use Or
              Transaction May Be Made By Your Legal Guardian Or Parents On The
              Website. Orbit Mart Reserves The Right To Terminate Your
              Registration And / Or Refuse To Provide You With Access To The
              Website If It Is Brought To Orbit Mart's Notice Or If It Is
              Discovered That You Are Under The Age Of 18 Years.
					</small>
				</p>
				<h6 className="Privacy_policy_subheading_text text-left py-3">
					Your Account and Registration Obligations
				</h6>
				<p>
					{' '}
					<small className="py-5">
					If You Use The Website, You Shall Be Responsible For Maintaining
              The Confidentiality Of Your Display Name And Password And You
              Shall Be Responsible For All Activities That Occur Under Your
              Display Name And Password. You Agree That If You Provide Any
              Information That Is Untrue, Inaccurate, Not Current Or Incomplete
              Or We Have Reasonable Grounds To Suspect That Such Information Is
              Untrue, Inaccurate, Not Current Or Incomplete, Or Not In
              Accordance With This Terms Of Use, We Shall Have The Right To
              Indefinitely Suspend Or Terminate Or Block Access Of Your
              Registration On The Website And Refuse To Provide You With Access
              To The Website.
					</small>
				</p>
				<h6 className="Privacy_policy_subheading_text text-left py-3">Communications</h6>
				<p>
					{' '}
					<small className="py-5">
					When You Use The Website Or Send Emails Or Other Data, Information
              Or Communication To Us, You Agree And Understand That You Are
              Communicating With Us Through Electronic Records And You Consent
              To Receive Communications Via Electronic Records From Us
              Periodically And As And When Required. We May Communicate With You
              By Email Or By Such Other Mode Of Communication, Electronic Or
              Otherwise.
					</small>{' '}
				</p>
				<h6 className="Privacy_policy_subheading_text text-left py-3">
					{' '}
					Platform For Transaction And Communication{' '}
				</h6>
				<p>
					{' '}
					<small className="py-5">
					The Website Is A Platform That Users Utilize To Meet And Interact
              With One Another For Their Transactions. Orbit Mart Is Not And
              Cannot Be A Party To Or Control In Any Manner Any Transaction
              Between The Website's Users.
					</small>
				</p>
				<h6 className="Privacy_policy_subheading_text text-left py-3"> Henceforward:</h6>
				<small>
					<ol>
						<li>
							{' '}
							All Commercial/Contractual Terms Are Offered By And Agreed To
                Between Customers And Merchants Alone. The
                Commercial/Contractual Terms Include Without Limitation Price,
                Shipping Costs, Payment Methods, Payment Terms, Date, Period And
                Mode Of Delivery, Warranties Related To Products And Services
                And After Sales Services Related To Products And Services. Orbit
                Mart Does Not Have Any Control Or Does Not Determine Or Advise
                Or In Any Way Involve Itself In The Offering Or Acceptance Of
                Such Commercial/Contractual Terms Between The Customer &
                Merchant.
						</li>
						<br />
						<li>
						Orbit Mart Does Not Make Any Representation Or Warranty As To
                Specifics (Such As Quality, Value, Saleability, Etc) Of The
                Products Or Services Proposed To Be Sold Or Offered To Be Sold
                Or Purchased On The Website. Orbit Mart Does Not Implicitly Or
                Explicitly Support Or Endorse The Sale Or Purchase Of Any
                Products Or Services On The Website. Orbit Mart Accepts No
                Liability For Any Errors Or Omissions, Whether On Behalf Of
                Itself Or Third Parties.
						</li>
						<br />
						<li>
						Orbit Mart Is Not Responsible For Any Non-Performance Or Breach
                Of Any Contract Entered Into Between Customers & Merchants.
                Orbit Mart Cannot And Does Not Guarantee That The Concerned
                Customers And/Or Merchants Will Perform Any Transaction
                Concluded On The Website. Orbit Mart Shall Not And Is Not
                Required To Mediate Or Resolve Any Dispute Or Disagreement
                Between Customers & Merchants.
						</li>
						<br />
						<li>
						Orbit Mart Does Not Make Any Representation Or Warranty As To
                The Itemspecifics (Such As Legal Title, Creditworthiness,
                Identity, Etc) Of Any Of Its Users. You Are Advised To
                Independently Verify The Bona Fides Of Any Particular User That
                You Choose To Deal With On The Website And Use Your Best
                Judgment In That Behalf.
						</li>
						<br />
						<li>
						Orbit Mart Does Not At Any Point Of Time During Any Transaction Between Customers And Merchants
						 On The Website Come Into Or Take Possession Of Any Of The Products Or Services Offered By Merchant
						  Nor Does It At Any Point Gain Title To Or Have Any Rights Or Claims Over The Products Or Services
						   Offered By Merchant To Customer.
						</li>
						<br />
						<li>
						At No Time Shall Orbit Mart Hold Any Right, Title Or Interest Over The Products Nor Shall Orbit Mart
						 Have Any Obligations Or Liabilities In Respect Of Such Contract Entered Into Between Customers And
						  Merchants. Orbit Mart Is Not Responsible For Unsatisfactory Or Delayed Performance Of Services Or
						   Damages Or Delays As A Result Of Products Which Are Out Of Stock, Unavailable Or Back Ordered.

						</li>
						<br />
						<li>
						The Website Is Only A Platform That Can Be Utilized By Users To Reach A Larger Base To Order Products Or Services. Orbit Mart Is Only Providing A Platform For Communication And It Is Agreed That The Contract For Sale Of Any Of The Products Or Services Shall Be A Strictly Bipartite Contract Between The Merchant And The Customer. At No Time Shall Orbit Mart Hold Any Right, Title Or Interest Over The Products Nor Shall Orbit Mart Have Any Obligations Or Liabilities In Respect Of Such Contract. Orbit Mart Is Not Responsible For Unsatisfactory Or Delayed Performance Of Services Or Damages Or Delays As A Result Of Products Which Are Out Of Stock, Unavailable Or Back Ordered.
						</li>
						<br />
						<li>
						You Shall Independently Agree Upon The Manner And Terms And Conditions Of Delivery, Payment, Insurance Etc. With The Merchant(S) That You Transact With. Disclaimer: Pricing On Any Product(S) As Is Reflected On The Website May Due To Some Technical Issue, Typographical Error Or Product Information Published By Merchant May Be Incorrectly Reflected And In Such An Event Merchant May Cancel Such Your Order(S).
						</li>
						<br />
						<li>
						You Release And Indemnify Orbit Mart And/Or Any Of Its Officers And Representatives From Any Cost, Damage, Liability Or Other Consequence Of Any Of The Actions Of The Users Of The Website And Specifically Waive Any Claims That You May Have In This Behalf Under Any Applicable Law. Notwithstanding Its Reasonable Efforts In That Behalf, Orbit Mart Cannot Take Responsibility Or Control The Information Provided By Other Users Which Is Made Available On The Website. You May Find Other User's Information To Be Offensive, Harmful, Inconsistent, Inaccurate, Or Deceptive. Please Use Caution And Practice Safe Trading When Using The Website.
						Please Note That There Could Be Risks In Dealing With Underage Persons Or People Acting Under False Pretence.
						</li>
					</ol>{' '}
				</small>
				<h6 className="Privacy_policy_subheading_text text-left py-3"> Charges</h6>
				<p>
					{' '}
					<small className="py-5">
					Registration On The Website Is Free For Customers. Orbit Mart Does
              Not Charge Any Fee For Browsing And Ordering On The Website. Orbit
              Mart Reserves The Right To Change Its Fee Policy From Time To
              Time. In Particular, Orbit Mart May At Its Sole Discretion
              Introduce New Services And Modify Some Or All Of The Existing
              Services Offered On The Website. In Such An Event Orbit Mart
              Reserves The Right To Introduce Fees For The New Services Offered
              Or Amend/Introduce Fees For Existing Services, As The Case May Be.
              Changes To The Fee Policy Shall Be Posted On The Website And Such
              Changes Shall Automatically Become Effective Immediately After
              They Are Posted On The Website. Unless Otherwise Stated, All Fees
              Shall Be Quoted In Indian Rupees. You Shall Be Solely Responsible
              For Compliance Of All Applicable Laws Including Those In India For
              Making Payments To Growup Sindhu Tchnology & Solutions Private
              Limited.
					</small>
				</p>

				<h6 className="Privacy_policy_subheading_text text-left py-3"> Use of the Website</h6>
				<p>
					{' '}
					<small className="py-5">
						{' '}
						You Agree, Undertake And Confirm That Your Use Of Website Shall Be
              Strictly Governed By The Following Binding Principles:
					</small>
				</p>
				<small className="text-lowercase">
					<ol>
						<li>
							{' '}
							You Shall Not Host, Display, Upload, Modify, Publish, Transmit,
                Update Or Share Any Information Which:
							<br />
							<br />
							(A) Belongs To Another Person And To Which You Does Not Have Any Right To;
							<br />
							<br />
							(B) Is Grossly Harmful, Harassing, Blasphemous, Defamatory,
                Obscene, Pornographic, Paedophilic, Libellous, Invasive Of
                Another's Privacy, Hateful, Or Racially, Ethnically
                Objectionable, Disparaging, Relating Or Encouraging Money
                Laundering Or Gambling, Or Otherwise Unlawful In Any Manner
                Whatever; Or Unlawfully Threatening Or Unlawfully Harassing
                Including But Not Limited To "Indecent Representation Of Women"
                Within The Meaning Of The Indecent Representation Of Women
                (Prohibition) Act, 1986;
							<br />
							<br />
							(C) Is Misleading In Any Way; <br />
							<br />
							(D) Is Patently Offensive To The Online Community, Such As
                Sexually Explicit Content, Or Content That Promotes Obscenity,
                Paedophilia, Racism, Bigotry, Hatred Or Physical Harm Of Any
                Kind Against Any Group Or Individual; <br />
							<br />
							(E) Harasses Or Advocates Harassment Of Another Person;<br />
							<br />
							(F) Involves The Transmission Of "Junk Mail", "Chain Letters",
							Or Unsolicited Mass Mailing Or "Spamming"; <br />
							<br />
							(G) Promotes Illegal Activities Or Conduct That Is Abusive,
							Threatening, Obscene, Defamatory Or Libellous;<br />
							<br />
							(H) Infringes Upon Or Violates Any Third Party's Rights
                [Including, But Not Limited To, Intellectual Property Rights,
                Rights Of Privacy (Including Without Limitation Unauthorized
                Disclosure Of A Person's Name, Email Address, Physical Address
                Or Phone Number) Or Rights Of Publicity];<br />
							<br />
							(I) Promotes An Illegal Or Unauthorized Copy Of Another Person's
                Copyrighted Work (See "Copyright Complaint" Below For
                Instructions On How To Lodge A Complaint About Uploaded
                Copyrighted Material), Such As Providing Pirated Computer
                Programs Or Links To Them, Providing Information To Circumvent
                Manufacture-Installed Copy-Protect Devices, Or Providing Pirated
                Music Or Links To Pirated Music Files; <br />
							<br />
							(J) Contains Restricted Or Password-Only Access Pages, Or Hidden
                Pages Or Images (Those Not Linked To Or From Another Accessible
                Page);<br />
							<br />
							(K) Provides Material That Exploits People In A Sexual, Violent
                Or Otherwise Inappropriate Manner Or Solicits Personal
                Information From Anyone;<br />
							<br />
							(L) Provides Instructional Information About Illegal Activities
                Such As Making Or Ordering Illegal Weapons, Violating Someone's
                Privacy, Or Providing Or Creating Computer Viruses;<br
							/>
							<br />
							(M) Contains Video, Photographs, Or Images Of Another Person
                (With A Minor Or An Adult).<br
							/>
							<br />
							(N) Tries To Gain Unauthorized Access Or Exceeds The Scope Of
                Authorized Access To The Website Or To Profiles, Blogs,
                Communities, Account Information, Bulletins, Friend Request, Or
                Other Areas Of The Website Or Solicits Passwords Or Personal
                Identifying Information For Commercial Or Unlawful Purposes From
                Other Users;<br />
							<br />
							(O) Engages In Commercial Activities And/Or Sales Without Our
                Prior Written Consent Such As Contests, Sweepstakes, Barter,
                Advertising And Pyramid Schemes, Or The Ordering Or Selling Of
                "Virtual" Products Related To The Website. Throughout This Terms
                Of Use, Orbit Mart's Prior Written Consent Means A Communication
                Coming From Orbit Mart's Legal Department, Specifically In
                Response To Your Request, And Specifically Addressing The
                Activity Or Conduct For Which You Seek Authorization;<br />
							<br />
							(P) Solicits Gambling Or Engages In Any Gambling Activity Which
                We, In Our Sole Discretion, Believes Is Or Could Be Construed As
                Being Illegal;<br />
							<br />
							(Q) Interferes With Another USER's Use And Enjoyment Of The
                Website Or Any Other Individual's User And Enjoyment Of Similar
                Services;<br />
							<br />(R) Refers To Any Website Or URL That, In Our Sole Discretion,
                Contains Material That Is Inappropriate For The Website Or Any
                Other Website, Contains Content That Would Be Prohibited Or
                Violates The Letter Or Spirit Of These Terms Of Use.<br />
							<br />
							(S) Harm Minors In Any Way;<br />
							<br />
							(T) Infringes Any Patent, Trademark, Copyright Or Other
                Proprietary Rights Or Third Party's Trade Secrets Or Rights Of
                Publicity Or Privacy Or Shall Not Be Fraudulent Or Involve The
                Sale Of Counterfeit Or Stolen Products;<br />
							<br />
							(U) Violates Any Law For The Time Being In Force;<br />
							<br />
							(V) Deceives Or Misleads The Addressee/ Users About The Origin
                Of Such Messages Or Communicates Any Information Which Is
                Grossly Offensive Or Menacing In Nature;<br />
							<br />
							(W) Impersonate Another Person;<br />
							<br />
							(X) Contains Software Viruses Or Any Other Computer Code, Files
                Or Programs Designed To Interrupt, Destroy Or Limit The
                Functionality Of Any Computer Resource; Or Contains Any Trojan
                Horses, Worms, Time Bombs, Cancelbots, Easter Eggs Or Other
                Computer Programming Routines That May Damage, Detrimentally
                Interfere With, Diminish Value Of, Surreptitiously Intercept Or
                Expropriate Any System, Data Or Personal Information; <br />
							<br />
							(Y) Threatens The Unity, Integrity, Defence, Security Or
                Sovereignty Of India, Friendly Relations With Foreign States, Or
                Public Order Or Causes Incitement To The Commission Of Any
                Cognizable Offence Or Prevents Investigation Of Any Offence Or
                Is Insulting Any Other Nation. <br />
							<br />
							(Z) Shall Not Be False, Inaccurate Or Misleading;<br />
							<br />  (Aa) Shall Not, Directly Or Indirectly, Offer, Attempt To Offer,
                Trade Or Attempt To Trade In Any Item, The Dealing Of Which Is
                Prohibited Or Restricted In Any Manner Under The Provisions Of
                Any Applicable Law, Rule, Regulation Or Guideline For The Time
                Being In Force.{' '}
							<br />
							<br /> (Ab) Shall Not Create Liability For Us Or Cause Us To Lose (In
                Whole Or In Part) The Services Of Our Internet Service Provider
                ("ISPs") Or Other Suppliers;
						</li>
						<br />
						<li>
						You Shall Not Use Any "Deep-Link", "Page-Scrape", "Robot",
                "Spider" Or Other Automatic Device, Program, Algorithm Or
                Methodology, Or Any Similar Or Equivalent Manual Process, To
                Access, Acquire, Copy Or Monitor Any Portion Of The Website Or
                Any Content, Or In Any Way Reproduce Or Circumvent The
                Navigational Structure Or Presentation Of The Website Or Any
                Content, To Obtain Or Attempt To Obtain Any Materials, Documents
                Or Information Through Any Means Not Purposely Made Available
                Through The Website. We Reserve Our Right To Bar Any Such
                Activity.
						</li>
						<br />
						<li>
							{' '}
							You Shall Not Attempt To Gain Unauthorized Access To Any Portion
                Or Feature Of The Website, Or Any Other Systems Or Networks
                Connected To The Website Or To Any Server, Computer, Network, Or
                To Any Of The Services Offered On Or Through The Website, By
                Hacking, Password "Mining" Or Any Other Illegitimate Means.
						</li>
						<br />
						<li>
							{' '}
							You Shall Not Probe, Scan Or Test The Vulnerability Of The
                Website Or Any Network Connected To The Website Nor Breach The
                Security Or Authentication Measures On The Website Or Any
                Network Connected To The Website. You May Not Reverse Look-Up,
                Trace Or Seek To Trace Any Information On Any Other User Of Or
                Visitor To Website, Or Any Other Customer, Including Any Account
                On The Website Not Owned By You, To Its Source, Or Exploit The
                Website Or Any Service Or Information Made Available Or Offered
                By Or Through The Website, In Any Way Where The Purpose Is To
                Reveal Any Information, Including But Not Limited To Personal
                Identification Or Information, Other Than Your Own Information,
                As Provided For By The Website.
						</li>
						<br />
						<li>
						You Shall Not Make Any Negative, Denigrating Or Defamatory
                Statement(S) Or Comment(S) About Us Or The Brand Name Or Domain
                Name Used By Us Including The Terms Orbit Mart, Orbitmart.Com,
                Or Otherwise Engage In Any Conduct Or Action That Might Tarnish
                The Image Or Reputation, Of Orbit Mart Or Merchant On Platform
                Or Otherwise Tarnish Or Dilute Any Orbit Mart's Trade Or Service
                Marks, Trade Name And/Or Goodwill Associated With Such Trade Or
                Service Marks, Trade Name As May Be Owned Or Used By Us. You
                Agree That You Will Not Take Any Action That Imposes An
                Unreasonable Or Disproportionately Large Load On The
                Infrastructure Of The Website Or Orbit Mart's Systems Or
                Networks, Or Any Systems Or Networks Connected To Orbit Mart.
						</li>
						<br />
						<li>
						You Agree Not To Use Any Device, Software Or Routine To
                Interfere Or Attempt To Interfere With The Proper Working Of The
                Website Or Any Transaction Being Conducted On The Website, Or
                With Any Other Person's Use Of The Website.
						</li>
						<br />
						<li>
						You May Not Forge Headers Or Otherwise Manipulate Identifiers In
                Order To Disguise The Origin Of Any Message Or Transmittal You
                Send To Us On Or Through The Website Or Any Service Offered On
                Or Through The Website. You May Not Pretend That You Are, Or
                That You Represent, Someone Else, Or Impersonate Any Other
                Individual Or Entity.{' '}
						</li>{' '}
						<br />
						<li>
						You May Not Use The Website Or Any Content For Any Purpose That
                Is Unlawful Or Prohibited By These Terms Of Use, Or To Solicit
                The Performance Of Any Illegal Activity Or Other Activity Which
                Infringes The Rights Of Orbit Mart And / Or Others.
						</li>
						<br />
						<li>
						You Shall At All Times Ensure Full Compliance With The
                Applicable Provisions Of The Information Technology Act, 2000
                And Rules Thereunder As Applicable And As Amended From Time To
                Time And Also All Applicable Domestic Laws, Rules And
                Regulations (Including The Provisions Of Any Applicable Exchange
                Control Laws Or Regulations In Force) And International Laws,
                Foreign Exchange Laws, Statutes, Ordinances And Regulations
                (Including, But Not Limited To Sales Tax/VAT, Income Tax,
                Octroi, Service Tax, Central Excise, Custom Duty, Local Levies)
                Regarding Your Use Of Our Service And Your Listing, Order,
                Solicitation Of Offers To Order, And Sale Of Products Or
                Services. You Shall Not Engage In Any Transaction In An Item Or
                Service, Which Is Prohibited By The Provisions Of Any Applicable
                Law Including Exchange Control Laws Or Regulations For The Time
                Being In Force.
						</li>
						<br />
						<li>
						Solely To Enable Us To Use The Information You Supply Us With,
                So That We Are Not Violating Any Rights You Might Have In Your
                Information, You Agree To Grant Us A Nonexclusive, Worldwide,
                Perpetual, Irrevocable, Royalty-Free, Sub-Licensable (Through
                Multiple Tiers) Right To Exercise The Copyright, Publicity,
                Database Rights Or Any Other Rights You Have In Your
                Information, In Any Media Now Known Or Not Currently Known, With
                Respect To Your Information. We Will Only Use Your Information
                In Accordance With The Terms Of Use And Privacy Policy
                Applicable To Use Of The Website.
						</li>
						<br />
						<li>
							{' '}
							From Time To Time, You Shall Be Responsible For Providing
                Information Relating To The Products Or Services Proposed To Be
                Sold By You. In This Connection, You Undertake That All Such
                Information Shall Be Accurate In All Respects. You Shall Not
                Exaggerate Or Over Emphasize The Attributes Of Such Products Or
                Services So As To Mislead Other Users In Any Manner.
						</li>
						<br />
						<li>
						You Shall Not Engage In Advertising To, Or Solicitation Of,
                Other Users Of The Website To Buy Or Sell Any Products Or
                Services, Including, But Not Limited To, Products Or Services
                Related To That Being Displayed On The Website Or Related To Us.
                You May Not Transmit Any Chain Letters Or Unsolicited Commercial
                Or Junk Email To Other Users Via The Website. It Shall Be A
                Violation Of These Terms Of Use To Use Any Information Obtained
                From The Website In Order To Harass, Abuse, Or Harm Another
                Person, Or In Order To Contact, Advertise To, Solicit, Or Sell
                To Another Person Other Than Us Without Our Prior Explicit
                Consent. In Order To Protect Our Users From Such Advertising Or
                Solicitation, We Reserve The Right To Restrict The Number Of
                Messages Or Emails Which A User May Send To Other Users In Any 24-Hour Period Which We Deems Appropriate In Its Sole
                Discretion. You Understand That We Have The Right At All Times
                To Disclose Any Information (Including The Identity Of The
                Persons Providing Information Or Materials On The Website) As
                Necessary To Satisfy Any Law, Regulation Or Valid Governmental
                Request. This May Include, Without Limitation, Disclosure Of The
                Information In Connection With Investigation Of Alleged Illegal
                Activity Or Solicitation Of Illegal Activity Or In Response To A
                Lawful Court Order Or Subpoena. In Addition, We Can (And You
                Hereby Expressly Authorize Us To) Disclose Any Information About
                You To Law Enforcement Or Other Government Officials, As We, In
                Our Sole Discretion, Believe Necessary Or Appropriate In
                Connection With The Investigation And/Or Resolution Of Possible
                Crimes, Especially Those That May Involve Personal Injury.
                <br />
                <br /> We Reserve The Right, But Has No Obligation, To Monitor The
                Materials Posted On The Website. Orbit Mart Shall Have The Right
                To Remove Or Edit Any Content That In Its Sole Discretion
                Violates, Or Is Alleged To Violate, Any Applicable Law Or Either
                The Spirit Or Letter Of These Terms Of Use. Notwithstanding This
                Right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE
                MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES.
                Please Be Advised That Such Content Posted Does Not Necessarily
                Reflect Orbit Mart Views. In No Event Shall Orbit Mart Assume Or
                Have Any Responsibility Or Liability For Any Content Posted Or
                For Any Claims, Damages Or Losses Resulting From Use Of Content
                And/Or Appearance Of Content On The Website. You Hereby
                Represent And Warrant That You Have All Necessary Rights In And
                To All Content Which You Provide And All Information It Contains
                And That Such Content Shall Not Infringe Any Proprietary Or
                Other Rights Of Third Parties Or Contain Any Libellous,
                Tortious, Or Otherwise Unlawful Information.
						</li>
						<br />
						<li>
							{' '}
							Your Correspondence Or Business Dealings With, Or Participation
                In Promotions Of, Advertisers Found On Or Through The Website,
                Including Payment And Delivery Of Related Products Or Services,
                And Any Other Terms, Conditions, Warranties Or Representations
                Associated With Such Dealings, Are Solely Between You And Such
                Advertiser. We Shall Not Be Responsible Or Liable For Any Loss
                Or Damage Of Any Sort Incurred As The Result Of Any Such
                Dealings Or As The Result Of The Presence Of Such Advertisers On
                The Website.
						</li>{' '}
						<br />
						<br />
						<li>
							{' '}
							It Is Possible That Other Users (Including Unauthorized Users Or
                "Hackers") May Post Or Transmit Offensive Or Obscene Materials
                On The Website And That You May Be Involuntarily Exposed To Such
                Offensive And Obscene Materials. It Also Is Possible For Others
                To Obtain Personal Information About You Due To Your Use Of The
                Website, And That The Recipient May Use Such Information To
                Harass Or Injure You. We Do Not Approve Of Such Unauthorized
                Uses, But By Using The Website You Acknowledge And Agree That We
                Are Not Responsible For The Use Of Any Personal Information That
                You Publicly Disclose Or Share With Others On The Website.
                Please Carefully Select The Type Of Information That You
                Publicly Disclose Or Share With Others On The Website.
						</li>
						<br />
						<li>
							{' '}
							Orbit Mart Shall Have All The Rights To Take Necessary Action
                And Claim Damages That May Occur Due To Your
                Involvement/Participation In Any Way On Your Own Or Through
                Group/S Of People, Intentionally Or Unintentionally In DoS/DDoS
                (Distributed Denial Of Services).
						</li>{' '}
					</ol>
				</small>

				<h6 className="Privacy_policy_subheading_text text-left py-3">Contents Posted On Site</h6>
				<small>
					<p>
						All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds,
						music and artwork (collectively, "Content"), is a third-party user generated content and Orbit
						Mart has no control over such third-party user generated content as Orbit Mart is merely an
						intermediary for the purposes of this Terms of Use.<br />
						<br />Except as expressly provided in these Terms of Use, no part of the Website and no Content
						may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded,
						translated, transmitted or distributed in any way (including "mirroring") to any other computer,
						server, Website or other medium for publication or distribution or for any commercial
						enterprise, without Orbit Mart's express prior written consent.<br />
						<br />
						You may use information on the products and services purposely made available on the Website for
						downloading, provided that You (1) do not remove any proprietary notice language in all copies
						of such documents, (2) use such information only for your personal, non-commercial informational
						purpose and do not copy or post such information on any networked computer or broadcast it in
						any media, (3) make no modifications to any such information, and (4) do not make any additional
						representations or warranties relating to such documents. <br />
						<br />
						You shall be responsible for any notes, messages, emails, billboard postings, photos, drawings,
						profiles, opinions, ideas, images, videos, audio files or other materials or information posted
						or transmitted to the Website (collectively, "Content"). Such Content will become Our property
						and You grant Us the worldwide, perpetual and transferable rights in such Content. We shall be
						entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law,
						use the Content or any of its elements for any type of use forever, including but not limited to
						promotional and advertising purposes and in any media whether now known or hereafter devised,
						including the creation of derivative works that may include the Content You provide. You agree
						that any Content You post may be used by us, consistent with Our Privacy Policy and Rules of
						Conduct on Site as mentioned herein, and You are not entitled to any payment or other
						compensation for such use.
						<h6 className="Privacy_policy_subheading_text text-left py-3">Privacy </h6>
						We view protection of Your privacy as a very important principle. We understand clearly that You
						and Your Personal Information is one of Our most important assets. We store and process Your
						Information including any sensitive financial information collected (as defined under the
						Information Technology Act, 2000), if any, on computers that may be protected by physical as
						well as reasonable technological security measures and procedures in accordance with Information
						Technology Act 2000 and Rules there under. If You object to Your Information being transferred
						or used in this way, please do not use Website.
						<br />
						<br /> We and our affiliates will share / sell / transfer / license / covey some or all of your
						personal information with another business entity should we (or our assets) plan to merge with
						or are acquired by that business entity, or re-organization, amalgamation, restructuring of
						business or for any other reason whatsoever. Should such a transaction or situation occur, the
						other business entity or the new combined entity will be required to follow the privacy policy
						with respect to your personal information. Once you provide your information to us, you provide
						such information to us and our affiliate and we and our affiliate may use such information to
						provide you various services with respect to your transaction whether such transaction is
						conducted on www.orbitmarts.com website.<br />
						<h6 className="Privacy_policy_subheading_text text-left py-3">
							{' '}
							Disclaimer of Warranties and Liability
						</h6>
						This Website, all the materials and products (including but not limited to software) and
						services, included on or otherwise made available to You through this site are provided on "as
						is" and "as available" basis without any representation or warranties, express or implied except
						otherwise specified in writing. Without prejudice to the forgoing paragraph, Orbit Mart does not
						warrant that: <br /> <br />
						• This Website will be constantly available, or available at all; or <br />
						<br />• The information on this Website is complete, true, accurate or non-misleading.<br />
						<br /> Orbit Mart will not be liable to You in any way or in relation to the Contents of, or use
						of, or otherwise in connection with, the Website. Orbit Mart does not warrant that this site;
						information, Content, materials, product (including software) or services included on or
						otherwise made available to You through the Website; their servers; or electronic communication
						sent from Us are free of viruses or other harmful components.<br />
						<br /> You will be required to enter a valid phone number while placing an order on the Website.
						By registering Your phone number with us, you consent to be contacted by Us via phone calls
						and/or SMS notifications, in case of any order or delivery related updates. We will not use your
						personal information to initiate any promotional phone calls or SMS.<br />
						<br />
						<h6 className="Privacy_policy_subheading_text text-left py-3">Services </h6>
						<b className="Privacy_policy_subheading_text">Payment</b>
						<br />
						<br /> While availing any of the payment method/s available on the Website, we will not be
						responsible or assume any liability, whatsoever in respect of any loss or damage arising
						directly or indirectly to You due to:<br />
						<br /> Lack of authorization for any transaction/s, or <br />
						<br />Exceeding the pre-set limit mutually agreed by You and between "Bank/s", or <br />
						<br />Any payment issues arising out of the transaction, or <br />Decline of transaction for any
						other reason/s All payments made against the purchases/services on Website by you shall be
						compulsorily in Indian Rupees acceptable in the Republic of India. Website will not facilitate
						transaction with respect to any other form of currency with respect to the purchases made on
						Website. <br />
						<br />Before shipping / delivering your order to you, Merchant may request you to provide
						supporting documents (including but not limited to Govt. issued ID and address proof) to
						establish the ownership of the payment instrument used by you for your purchase. This is done in
						the interest of providing a safe online shopping environment to Our Users. <br />
						<br />
						<b className="Privacy_policy_subheading_text text-left pt-3 pb-3">Further:</b> <br />
						<br />1. Transactions, Transaction Price and all commercial terms such as Delivery, Dispatch of
						products and/or services are as per principal-to-principal bipartite contractual obligations
						between Customer and Merchant and payment facility is merely used by the Customer and Merchant
						to facilitate the completion of the Transaction. Use of the payment facility shall not render
						Orbit Mart liable or responsible for the non-delivery, non-receipt, nonpayment, damage, breach
						of representations and warranties, non-provision of after sales or warranty services or fraud as
						regards the products and /or services listed on Orbit Mart's Website.<br />
						<br />
						2. You have specifically authorized Orbit Mart or its service providers to collect, process,
						facilitate and remit payments and /or the Transaction Price electronically or through Cash on
						Delivery to and from other Users in respect of transactions through Payment Facility. Your
						relationship with Orbit Mart is on a principal-to-principal basis and by accepting these Terms
						of Use you agree that Orbit Mart is an independent contractor for all purposes, and does not
						have control of or liability for the products or services that are listed on Orbit Mart's
						Website that are paid for by using the Payment Facility. Orbit Mart does not guarantee the
						identity of any User nor does it ensure that a customer or a Merchant will complete a
						transaction. <br />
						<br />3. You understand, accept and agree that the payment facility provided by Orbit Mart is
						neither a banking nor financial service but is merely a facilitator providing an electronic,
						automated online electronic payment, receiving payment through Cash on Delivery, collection and
						remittance facility for the Transactions on the Orbit Mart Website using the existing authorized
						banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment
						Facility, Orbit Mart is neither acting as trustees nor acting in a fiduciary capacity with
						respect to the Transaction or the Transaction Price. <br />
						<br />
						<b className="Privacy_policy_subheading_text">Payment Facility for Customers:</b>
						<br />
						<br /> • You, as a Customer, understand that upon initiating a Transaction You are entering into
						a legally binding and enforceable contract with the Merchant to order the products and /or
						services from the Merchant using the Payment Facility, and You shall pay the Transaction Price
						through Your Issuing Bank to the Merchant using Payment Facility.<br />
						<br /> • You, as a Customer, may agree with the Merchant through electronic communication and
						electronic records and using the automated features as may be provided by Payment Facility on
						any extension / increase in the Dispatch and/or Delivery time and the Transaction shall stand
						amended to such extent. Any such extension / increase of Dispatch / Delivery time or subsequent
						novation / variation of the Transaction should be in compliance with Payment Facility Rules and
						Policies. <br />
						<br />• You, as a Customer, shall electronically notify Payment Facility using the appropriate
						Orbit Mart Website features immediately upon Delivery or non-Delivery within the time period as
						provided in Policies. Non notification by You of Delivery or non-Delivery within the time period
						specified in the Policies shall be construed as a deemed Delivery in respect of that
						Transaction. In case of Cash on Delivery transactions, Customer is not required to confirm the
						receipt of products or services.<br />
						<br />
						• You, as a Customer, shall be entitled to claim a refund of the Transaction Price (as Your sole
						and exclusive remedy) in case You do not receive the Delivery within the time period agreed in
						the Transaction or within the time period as provided in the Policies, whichever is earlier. In
						case you do not raise a refund claim using Website features within the stipulated time than this
						would make You ineligible for a refund.<br />
						<br /> • You, as a customer, understand that the Payment Facility may not be available in full
						or in part for certain category of products and/or services and/or Transactions as mentioned in
						the Policies and hence You may not be entitled to a refund in respect of the Transactions for
						those products and /or services <br />
						<br />• Except for Cash on Delivery transaction, refund, if any, shall be made at the same
						Issuing Bank from where Transaction Price was received. <br />
						<br />• For Cash on Delivery transactions, refunds, if any, will be made by us in favour of the
						Customer (As per registration details provided by the Customer)<br />
						<br /> • Refund shall be made in Indian Rupees only and shall be equivalent to the Transaction
						Price received in Indian Rupees. <br />
						<br />• For electronics payments, refund shall be made through payment facility using NEFT /
						RTGS or any other online banking / electronic funds transfer system approved by Reserve Bank
						India (RBI). <br />
						<br />• Refund shall be conditional and shall be with recourse available to Orbit Mart in case
						of any misuse by Customer.<br />
						<br />
						• Refund shall be subject to Customer complying with Policies.<br />
						<br /> 4. Orbit Mart reserves the right to impose limits on the number of Transactions or
						Transaction Price which Orbit Mart may receive from on an individual Valid Credit/Debit/ Cash
						Card / Valid Bank Account/ and such other infrastructure or any other financial instrument
						directly or indirectly through payment aggregator or through any such facility authorized by
						Reserve Bank of India to provide enabling support facility for collection and remittance of
						payment or by an individual Customer during any time period, and reserves the right to refuse to
						process Transactions exceeding such limit. <br />
						<br /> 5. Orbit Mart reserves the right to refuse to process Transactions by Customers with a
						prior history of questionable charges including without limitation breach of any agreements by
						Customer with Orbit Mart or breach/violation of any law or any charges imposed by Issuing Bank
						or breach of any policy.<br />
						<br /> 6. Orbit Mart may do such checks as it deems fit before approving the receipt of/Customer
						commitment to pay (for Cash on Delivery transactions) Transaction Price from the Customer for
						security or other reasons at the discretion of Orbit Mart. As a result of such check if Orbit
						Mart is not satisfied with the creditability of the Customer or genuineness of the Transaction /
						Transaction Price, it will have the right to reject the receipt of / Customer commitment to pay
						Transaction Price.<br />
						<br /> 7. Orbit Mart may delay notifying the payment confirmation i.e., informing Merchant to
						Dispatch, if Orbit Mart deems suspicious or for Customers conducting high transaction volumes to
						ensure safety of the Transaction and Transaction Price. In addition, Orbit Mart may hold
						Transaction Price and Orbit Mart may not inform Merchant to Dispatch or remit Transaction Price
						to law enforcement officials (instead of refunding the same to Customer) at the request of law
						enforcement officials or in the event the Customer is engaged in any form of illegal activity.{' '}
						<br />
						<br /> 8. The Customer and Merchant acknowledge that Orbit Mart will not be liable for any
						damages, interests or claims etc. resulting from not processing a Transaction/Transaction Price
						or any delay in processing a Transaction/Transaction Price which is beyond control of Orbit
						Mart. Compliance with Laws: <br />
						<br /> 9. Customer and Merchant shall comply with all the applicable laws (including without
						limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued
						there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time
						to time, Customs Act, Information and Technology Act, 2000 as amended by the Information
						Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made
						there under, Foreign Contribution Regulation Act, 1976 and the rules made there under, Income
						Tax Act, 1961 and the rules made there under, Export Import Policy of government of India)
						applicable to them respectively for using Payment Facility and Orbit Mart Website. <br />
						<br />
						<b className="Privacy_policy_subheading_text text-left">
							Customer's arrangement with Issuing Bank:{' '}
						</b>
						<br />
						<br /> 10. All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed
						using a Credit Card payment gateway or appropriate payment system infrastructure and the same
						will also be governed by the terms and conditions agreed to between the Customer and the
						respective Issuing Bank and payment instrument issuing company.<br />
						<br /> 11. All Online Bank Transfers from Valid Bank Accounts are processed using the gateway
						provided by the respective Issuing Bank which support Payment Facility to provide these services
						to the Users. All such Online Bank Transfers on Payment Facility are also governed by the terms
						and conditions agreed to between Customer and the respective Issuing Bank. <br />
						<br />
						<b className="Privacy_policy_subheading_text">Wallet</b>
						<br />
						<br /> The Wallet ("Wallet") is a pre-paid payment instrument which is associated with your
						website account. In which you maintain a balance money and redeem it at your convenience. Please
						note that our Distributor/ Franchiser will be the issuer of wallet and he/she shall be
						responsible for redeeming the money lying to your credit of your wallet account and our company
						does not assume any risk, liability or responsibility with respect to wallet money.<br />
						<br /> Distributor/Franchiser will not pay any interest on the amount maintained by you in your
						distributor/ Franchiser specific Wallet. <br />
						<br />
						<b className="Privacy_policy_subheading_text">Refill of Wallet:</b> Your Wallet can be refilled,
						using any of the pre-paid payment options only. You will be able to recharge your Wallet up to
						an amount of ₹10,000 in one instance. There is no need to maintain a minimum balance.<br />
						<br />
						<b className="Privacy_policy_subheading_text"> Refund of Wallet Amount: </b>Your Wallet
						comprises of three types of balances - Store credit (which is a credit granted by Us typically
						in lieu of a cancelled order), Topped-up balance (which is a prepaid amount that You added to
						the Wallet to pay for future orders on the Website) and Promotional balance (which is credit
						granted by Us purely for promotional or goodwill purposes). Topped-up balance (prepaid amount)
						as well as the Promotional balance added to the Wallet will be non-refundable while Store credit
						will continue to be entirely refundable.<br />
						<br />
						<b className="Privacy_policy_subheading_text">Limitation of Liability:</b> In no event will our
						company/Orbit Mart will be liable for any money lying to your credit in wallet, any special,
						incidental, indirect or consequential damages or losses of any kind, arising from the use of
						Wallet or association with the Wallet.<br />
						<br />
						<b className="Privacy_policy_subheading_text text-left">
							Order Cancellation or Replacement
						</b>{' '}
						<br />
						<br /> Customer will cancel his order through our system/ mobile app. If the product is damage
						or have any defect then customers will be claimed to replaced it. The whole responsibility of
						replacement is on our registered merchant. <br />
						<br /> If Orbit Mart has suspicion or knowledge, that any of its Customer and Merchant are
						involved in any activity that is intended to provide claims or information that is false,
						misleading or not genuine, then Orbit Mart may while reserving its rights to initiate civil
						and/or criminal proceedings against member may also at its sole discretion suspend, block,
						restrict, cancel the Display Name of such Customer and Merchant and /or disqualify that user and
						any related users from availing protection through this program. <br />
						<br /> Orbit Mart reserves its right to initiate civil and/or criminal proceedings against a
						user who, files an invalid and/or false claims or provides false, incomplete, or misleading
						information. In addition to the legal proceedings as aforesaid, Orbit Mart may at its sole
						discretion suspend, block, restrict, cancel the Display Name [and its related Display Names] of
						such user and/or disqualify that user and any related users from availing protection through
						this program. Any person who, knowingly and with intent to injure, defraud or deceive, files a
						Fraudulent Complaint containing false, incomplete, or misleading information may be guilty of a
						criminal offence and will be prosecuted to the fullest extent of the law. <br />
						<br />
						<b className="Privacy_policy_subheading_text text-left">Intellectual Property Rights: </b>
						<br />
						<br />Intellectual Property Rights for the purpose of this Terms of Use shall always mean and
						include copyrights whether registered or not, patents including rights of filing patents,
						trademarks, trade names, trade dresses, house marks, collective marks, associate marks and the
						right to register them, designs both industrial and layout, geographical indicators, moral
						rights, broadcasting rights, displaying rights, distribution rights, selling rights, abridged
						rights, translating rights, reproducing rights, performing rights, communicating rights,
						adapting rights, circulating rights, protected rights, joint rights, reciprocating rights,
						infringement rights. All those Intellectual Property rights arising as a result of domain names,
						internet or any other right available under applicable law shall vest in the domain of our
						Company/Orbit Mart as the owner of such domain name. The Parties hereto agree and confirm that
						no part of any Intellectual Property rights mentioned hereinabove is transferred in the name of
						User and any intellectual property rights arising as a result of these presents shall also be in
						the absolute ownership, possession and Our control or control of its licensors, as the case may
						be.<br />
						<br /> <b className="Privacy_policy_subheading_text">Indemnity</b>
						<br />
						<br /> You shall indemnify and hold harmless Orbit Mart, its owner, licensee, affiliates,
						subsidiaries, group companies (as applicable) and their respective officers, directors, agents,
						and employees, from any claim or demand, or actions including reasonable attorneys' fees, made
						by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use,
						privacy Policy and other Policies, or Your violation of any law, rules or regulations or the
						rights (including infringement of intellectual property rights) of a third party. <br />
						<br />
						<b className="Privacy_policy_subheading_text">Applicable Law</b>
						<br />
						<br /> Terms of Use shall be governed by and interpreted and construed in accordance with the
						laws of India. The place of jurisdiction shall be exclusively in Sindhudurg District.<br />
						<br /> <b className="Privacy_policy_subheading_text">Jurisdictional Issues/Sale</b> <br />
						<br />in India Only Unless otherwise specified, the material on the Website is presented solely
						for the purpose of sale in India. Orbit Mart make no representation that materials in the
						Website are appropriate or available for use in other locations/Countries other than India.
						Those who choose to access this site from other locations/Countries other than India do so on
						their own initiative and Orbit Mart is not responsible for supply of products/refund for the
						products ordered from other locations/Countries other than India, compliance with local laws, if
						and to the extent local laws are applicable<br />
						<br /> <b className="Privacy_policy_subheading_text">Trademark, Copyright and Restriction </b>
						<br />
						<br />This site is controlled and operated by Growup Sindhu Technology & Solutions Pvt Ltd and
						products are sold by respective Merchants. All material on this site, including images,
						illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other
						intellectual property rights. Material on Website is solely for Your personal, noncommercial
						use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material
						in any way, including by email or other electronic means and whether directly or indirectly and
						You must not assist any other person to do so. Without the prior written consent of the owner,
						modification of the materials, use of the materials on any other website or networked computer
						environment or use of the materials for any purpose other than personal, non-commercial use is a
						violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use
						for which You receive any remuneration, whether in money or otherwise, is a commercial use for
						the purposes of this clause.<br />
						<br />
						<b className="Privacy_policy_subheading_text">Trademark complaint </b>
						<br />
						<br />Orbit Mart respects the intellectual property of others. In case You feel that Your
						Trademark has been infringed, you can write to Orbit Mart on www.orbitmarts.com.<br />
						<br />
						<b className="Privacy_policy_subheading_text">Product Description</b> <br />
						<br /> Orbit Mart we do not warrant that Product description or other content of this Website is
						accurate, complete, reliable, current, or error-free and assumes no liability in this regard.<br />
						<br />
						<b className="Privacy_policy_subheading_text">Limitation of Liability</b> <br />
						<br /> IN NO EVENT SHALL ORBIT MART BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR
						CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN
						INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.<br />
						<br /> <b className="Privacy_policy_subheading_text">Contact Us</b> <br />
						<br />Please Contact Us for any questions or comments (including all inquiries unrelated to
						copyright infringement) regarding this Website.<br />
						<br /> <b className="Privacy_policy_subheading_text">Grievance officer</b> <br />
						<br />In accordance with Information Technology Act 2000 and rules made there under, the name
						and contact details of the Grievance Officer are provided below:<br />
						<br /> <b className="Privacy_policy_subheading_text">Director :- </b>
						<br />
						<br />Mr. Rajaram Gurunath Samant <br />
						<br />Mrs. Manjiri Deepak Dabholkar<br />
						<br />{' '}
						<b className="Privacy_policy_subheading_text">
							Growup Sindhu Technology & Solutions Private Limited{' '}
						</b>
						<br />
						<br />H No.3158 Shivshakti-2, Near Kudaleshwar Mandir, Kudal, <br />Dist- Sindhudurg,
						Maharashtra- 416520.India.<br />
						<br />
						<b className="Privacy_policy_subheading_text">Phone:</b> +91 -8109099709 <br />
						<b className="Privacy_policy_subheading_text">Email:</b> growupsindhu@gmail.com <br />
						<br />
						<b className="Privacy_policy_subheading_text">POLICIES</b>
						<br />
						<br />
						<b className="Privacy_policy_subheading_text">Profanity Policy</b>
						<br />
						<br /> Orbit Mart prohibits the use of language that is racist, hateful, sexual or obscene in
						nature in a public area.<br />
						<br /> This policy extends to text within listings, on Merchant pages and all other areas of the
						site that another User may view. If the profane words are part of a title for the item being
						sold, we allow Merchants to 'blur' out the bulk of the offending word with asterisks (i.e., s***
						or f***). <br />
						<br />Please report any violations of this policy to the correct area for review: <br />
						<br />Report offensive Display Names<br />
						<br /> Report offensive language in a listing or otherwise<br />
						<br /> If a feedback comment; or any communication made between Users on the Website; or email
						communication between Users in relation to transactions conducted on Website contain profanity,
						please review Our feedback removal policy and submit a request for action/removal.<br />
						<br />
						Disciplinary action may result in the indefinite suspension of a user’s account, temporary
						suspension, or a formal warning.<br />
						<br /> Orbit Mart will consider the circumstances of an alleged policy violation and the user's
						trading records before taking action.<br />
						<br /> Violations of this policy may result in a range of actions, including: <br />
						<br />Limits placed on account privileges;<br />
						<br /> Loss of special status; <br />
						<br />Account suspension.<br />
						<br />
						<b> Replacement Guarantee</b>
						<br />
						<br /> The Replacement Guarantee seeks to assist Customers who have been defrauded by registered
						Merchants on the Website. If at the time of delivery of the product/s, if any defect is found,
						then the Customer can ask for replacement of the product/s from the Merchant.<br />
						<br /> If Orbit Mart has suspicion or knowledge, that any of its Customers and Merchants are
						involved in any activity that is intended to provide claims or information that is false,
						misleading or not genuine, then Orbit Mart may while reserving its rights to initiate civil
						and/or criminal proceedings against User may also at its sole discretion suspend, block,
						restrict, cancel the Display Name of such Customer and Merchant and /or disqualify that User and
						any related Users from availing protection through this program.<br />
						<br /> Orbit Mart reserves its right to initiate civil and/or criminal proceedings against a
						User who, files invalid and/or false claims or provides false, incomplete, or misleading
						information. In addition to the legal proceedings as aforesaid, Orbit Mart may at its sole
						discretion suspend, block, restrict, cancel the Display Name [and its related Display Names] of
						such User and/or disqualify that User and any related Users from availing protection through
						this program. Any person who, knowingly and with intent to injure, defraud or deceive, files a
						Fraudulent Complaint containing false, incomplete, or misleading information may be guilty of a
						criminal offence and will be prosecuted to the fullest extent of the law. <br />
						<br />
						<b>Returns Policy</b>
						<br />
						<br /> Definition:'Return' is defined as the action of giving back the item ordered by the
						Customer to the Merchant on the Orbit Mart website. Following situations may arise:<br />
						<br />
						Item was defective <br />
						<br />Item was damaged during the Shipping <br />
						<br />Products was / were missing<br />
						<br /> Wrong item was sent by the Merchant.<br />
						<br /> Return could also result in refund of money in most of the cases.<br />
						<br />
						<b> Points to be noted:</b>
						<br />
						<br />
						Merchant can always accept the return irrespective of the policy.<br />
						<br /> If Merchant disagrees a return request, Customer can file a dispute under the Customer
						Protection Program. <br />
						<br />We encourage the Customer to review the listing before making the Purchase decision. In
						case Customer orders a wrong item, Customer shall not be entitled to any return/refund. <br />
						<br />On certain select days as specified by Orbit Mart (such as ‘Today’s Best Deals’) separate
						policies may be applicable. <br />
						<br />In the event the Merchant accepts the return request raised by the Customer, Customer will
						have to return the product and then the refund shall be credited/give to the Customer account.<br />
						<br /> Shipping cost for returning the product shall be borne and incurred by the Merchant.<br />
						<br /> <b>Replacement </b>
						<br />
						<br />Definition: Replacement is the action or process of replacing something in place of
						another. A Customer can request for replacement whenever he is not happy with the item, reason
						being Damaged in shipping, Defective item, wrong item shipped and the like.<br />
						<br /> <b>Points to be noted: </b>
						<br />
						<br />Merchant can always accept the return irrespective of the policy. <br />
						<br />If Merchant disagrees for a return request, Customer can file a dispute under Customer
						Protection Program.<br />
						<br /> 1. An intimation shall be provided to Merchant seeking either "approval" or "rejection"
						of the replacement request. <br />
						<br />2. In case the Merchant accepts the replacement request, Customer shall be required to
						return the product to the Merchant and only after return of the product, Merchant shall be
						obliged to provide the replacement product to the Merchant. <br />
						<br />3. Incase Merchant rejects the replacement request, Customer can choose to raise a dispute
						by writing to www.orbit marts.com. <br />
						<br />4. In case the Merchant doesn't have the product at all, Merchant can provide the refund
						to the Customer and Customer shall be obligated to accept the refund in lieu of replacement. All
						the product parameters shall be required to be complied with in cases of replacement. <br />
						<br />5. All shipping and other replacement charges shall be borne and incurred by the Merchant.{' '}
						<br />
						<br />
						<b>Disputes (Resolutions) Policy</b> <br />
						<br />
						<b>Overview</b> <br />
						<br />Generally, transactions are conducted smoothly on Orbit Mart. However, there may be some
						cases where both the Customer and Merchant may face issues. At Orbit Mart, we have a Dispute
						Resolution process in order to resolve disputes between Customer and Merchant.<br />
						<br /> <b>What is a 'dispute'?</b> <br />
						<br />A 'Dispute' can be defined as a disagreement between a Customer and a Merchant in
						connection with a transaction on the Website.<br />
						<br /> <b>How does a 'dispute' occur in the Marketplace? </b>
						<br />
						<br />Disputes are filed as a result of a disagreement between the Customer and the Merchant.
						Disputes arise out of an issue that is raised by either party not being completely satisfied
						with the resolution of their complaint/issue. <br />
						<br />It is important that before a Customer/Merchant raises a dispute, they should attempt to
						solve the issue. Please note that whenever Customer raises a dispute, the Merchant's payment for
						that order is put on hold immediately until the issue is resolved.<br />
						<br />
						<b> What are the various types of 'disputes'?</b> <br />
						<br />Following are the indicative examples of potential disputes:<br />
						<br /> 1. Wrong item received <br />
						<br />2. Item/Product Not as described<br />
						<br /> 3. Damaged or Seal broken on Product <br />
						<br />4. Merchant Description/Specification Wrong <br />
						<br />5. Defective (Functional issues) <br />
						<br />In case the Merchant rejects the return request of the Customer, and Customer raises a
						dispute, then our registered Distributor/Franchiser will try to mediate and resolve the dispute
						between both the parties. If the dispute is resolved in favour of the Customer, a refund is
						provided once the product is returned to the Merchant. If the dispute is settled in favour of
						the Merchant, Customer is entitled to any refund.<br />
						<br /> <h6 className="Privacy_policy_subheading_text">Customer Protection Policies</h6>
						<br /> In case of a dispute where the Merchant is unable to provide a refund or a replacement,
						Orbit Mart will actively work towards reaching a resolution. <br />
						<br />The Customer Protection Program covers Customer who are unable to successfully resolve
						their dispute with the Merchant or are not satisfied the resolution provided by the Merchant.{' '}
						<br />
						<br />The Customer can write to www.orbitmart.com if the issue with the Merchant is not
						resolved. Orbit Mart's Customer Support team will look into the case to check for possible fraud
						and if the Customer has been blacklisted/blocked from making purchases on the Website. Only
						after verifying these facts, a dispute can be registered.<br />
						<br /> In due course of resolution, Orbit Mart's Customer Support Team will facilitate a
						conference call including the Merchant and Customer.<br />
						<br /> When a dispute has been raised, Orbit Mart may provide both the parties access to each
						other Display Names, contact details including email addresses and other details pertaining to
						the dispute.Customer and Merchant are subject to final consent from Orbit Mart for settling the
						dispute. <br />
						<br /> Customer Eligibility and Restrictions<br />
						<br /> • Only the Customer who have purchased the product on Orbit Mart are eligible for the
						Customer Protection Program.<br />
						<br />
						• Any damage or loss to the product after delivery will not be covered under this policy and
						will completely be the Customer responsibility. Customer should refuse to accept delivery if the
						item is damaged. <br />
						<br /> • To be able to take advantage of the Customer Protection Policies, Customer should first
						contact the Merchant and attempt to resolve the issue. If the Customer doesn't hear from the
						Merchant or is unable to resolve the issue with the Merchant even after contact, a dispute can
						be raised with Orbit Mart by writing an email to www.orbitmart.com<br />
						<br /> • Fraudulent charges and claims are not covered under Customer Protection Policies.<br />
						<br /> • If the Customer has already initiated chargeback through the credit card issuing bank,
						it will not be covered under Customer Protection Policies, though in such cases a Merchant can
						file a claim through the Merchant Protection Policies.<br />
						<br /> • Blacklisted and Blocked Customer are not covered by the Customer Protection Policies.<br />
						<br /> • Through the Customer Protection program, Orbit Mart does not provide any
						guarantee/warranty to Customer for products sold on Orbit Mart against technical/manufacturing
						defects.<br />
						<br />
						• Orbit Mart shall at no point be responsible for any direct or indirect losses, expenses, costs
						of any nature whatsoever that may be incurred by any Customer/ Merchant. <br />
						<br /> • Claims of the nature of 'Customer remorse' (i.e., instances where products are bought
						by the Customer by mistake or where the Customer chooses to change his/her mind with regard to
						the product purchased by him) will not be entertained through this policy. <br />
						<br /> • Orbit Mart reserves its right to initiate civil and/or criminal proceedings against a
						User who, files an invalid and/or false claims or provides false, incomplete, or misleading
						information. In addition to the legal proceedings as aforesaid, Orbit Mart may at its sole
						discretion suspend, block, restrict, cancel the Display Name [and its related Display Names] of
						such User and/or disqualify that user and any related users from availing protection through
						this policy.<br />
						<br /> • Decisions made by Orbit Mart under the Customer Protection Policy shall be final and
						binding on its Users.<br />
						<br /> • Orbit Mart reserves the right to modify / discontinue Customer Protection Policy
						without any prior notice period to its Users. <br />
						<br /> <b>Disputes via Chargeback</b>
						<br />
						<br />{' '}
						<b>
							Whenever a chargeback (CB) comes from a payment gateway/bank, following situations may
							arise:{' '}
						</b>
						<br />
						<br />1. Item not received CB - Customer hasn't received the item. Refund will be created in
						accordance with the dispute policies <br />
						<br />2. Unauthorized CB - Customer hasn't made this particular transaction. Refund will be
						created in accordance with the dispute policies.<br />
						<br /> Merchant expressly agrees that issuing the correct and complete invoice is the sole and
						primary responsibility of the Merchant. Furthermore, Merchant shall ensure that invoices state
						"Powered by Orbit Mart" and failing to do so Merchant will be liable for chargebacks (as
						applicable). <br />
						<br />3. Item/Product not as described - meaning item/product is not what Customer
						expected.Dispute will be decided in accordance with the dispute policies. <br />
						<br />
						<b>Email Abuse & Threat Policy</b>
						<br />
						<br /> Private communication, including email correspondence, is not regulated by Orbit Mart.
						Orbit Mart encourages its Users to be professional, courteous and respectful when communicating
						by email.<br />
						<br />However, Orbit Mart will investigate and can take action on certain types of unwanted
						emails that violate Orbit Mart policies.
						<br />
						<br />
						<b> Such instances:</b> <br />
						<br /> Threats of Bodily Harm – Orbit Mart does not permit Users to send explicit threats of
						bodily harm.<br /> <br />Misuse of Orbit Mart System – Orbit Mart allows Users to facilitate
						transactions through the Orbit Mart system, but will investigate any misuse of this service.<br />{' '}
						<br />Spoof (Fake) email – Orbit Mart will never ask you to provide sensitive information
						through email. In case you receive any spoof (fake) email, you are requested to report the same
						to Us through 'Contact Us' tab.<br />
						Spam (Unsolicited Commercial email) – Orbit Mart's spam policy applies only to unsolicited
						commercial messages sent by Orbit Mart Users. Orbit Mart Users are not allowed to send spam
						messages to other Users.<br /> <br />Orbit Mart policy prohibits user-to-user threats of
						physical harm via any method including, phone, email and on Our public message boards.<br />
						<br />
						Violations of this policy may result in a range of actions, including:<br />
						<br />
						Limits on account privileges<br /> <br />Account suspension <br />
						<br />Cancellation of listings<br />
						<br />
						Loss of special status <br />
						<br />Other Businesses
						<br /> <br />Orbit Mart does not take responsibility or liability for the actions, products,
						content and services on the Website, which are linked to Affiliates and / or third- party
						websites using Website's APIs or otherwise. In addition, the Website may provide links to the
						third- party websites of Our affiliated companies and certain other businesses for which, Orbit
						Mart assumes no responsibility for examining or evaluating the products and services offered by
						them. Orbit Mart do not warrant the offerings of, any of these businesses or individuals or the
						content of such third- party website(s). Orbit Mart does not endorse, in any way, any third-
						party website(s) or content thereof. <br />
						<br />Orbit Mart does not and cannot verify that Merchant have the right or ability to sell or
						distribute their listed products. However, Orbit Mart is committed to removing infringing or
						unlicensed products once an authorized representative of the rights owner properly reports them
						to Orbit Mart.
					</p>
				</small>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
