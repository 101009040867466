import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../context/ContextProvider";
import oil from "../assets/img/oil.jpg";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { addToCart, deleteFromCart, getCart } from "./helpers/localstorage";

const BestOfferall = () => {
  const { offers } = useContext(StateContext);
  console.log(offers);
  const { pincode } = useContext(StateContext);
  const { setCart } = useContext(StateContext);
  const [offersData, setOffersData] = useState([]);

  function getOffers() {
    // debugger;
    axios
      .get(`http://143.244.132.221:4002/api/v1/offer/merchants/${pincode}`)
      .then((res) => {
        // debugger;
        console.log(res);
        setOffersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOffers();
  }, []);

  const addCartProduct = (productId) => {
    console.log(productId);
    addToCart(productId[0]);
    setCart(getCart());
  };

  const fixValue = "Rs Off";

  const perValue = "% Off";
  return (
    <div>
      <div>
        <h1
          style={{ marginLeft: 45, marginTop: 10 }}
          className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12"
        >
          Best Offers
        </h1>
      </div>
      <div className=" container">
        <div className="row mb-2">
          {offersData
            .filter((data) => data.offerType === "BEST")
            .map(
              (
                { discountType, offerDiscount, productId, userId, _id },
                index
              ) => (
                <div className="product-container" key={_id}>
                  <div className="item img-thumbnail text-center pb-1 mx-auto  mb-3 tody">
                    <p className=" bg-gr p-2 text-white rounded-top fw-bold">
                      {discountType == "FIXED"
                        ? offerDiscount + "" + fixValue
                        : offerDiscount + "" + perValue}
                      <br />
                      {userId.firmName}
                    </p>
                    <img
                      src={productId[0].image}
                      style={{
                        paddingTop: "10px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        width: "150px",
                        height: "80px",
                        margin: "auto",
                      }}
                    />

                    <h6 className="p-3 fw-bold pt-4">
                      {productId[0].itemName}
                    </h6>
                    <div className="d-flex justify-content-between ps-3 pe-3 pb-3">
                      <div className="bg-gr ps-2 pe-2">
                        {4.2} <AiFillStar />
                      </div>
                      <div className="fw-bold m-2 text-body1">
                        ₹{productId[0].itemPrice}
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn-sm txt-bl fw-bold w-100 text-center mx-auto"
                      onClick={() => addCartProduct(productId)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default BestOfferall;
